@import '../../../../../styles/responsive';

.parent {
  &Info {
    display: flex;
    align-items: center;
    column-gap: 16px;
    @include device(extraMediumSmall) {
      justify-content: center;
    }
  }

  &InfoStatus {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    border-radius: 4px;
    padding: 8px 8px 8px 8px;
    background-color: var(--primary-100);
    height: min-content;
  }

  &Head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 8px;
  }

  &InfoDescription {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-05);
    margin: 0 0 32px 0;
    @include device(extraMediumSmall) {
      text-align: center;
    }
  }

  &Modal {
    width: 300px;
    text-align: center;
  }

  &ModalButtons {
    margin: 30px 0 0 0;
    display: flex;
    width: 100%;
    height: 40px;
    column-gap: 15px;
  }

  &ServiceWrapper {
    width: 685px;
    @include device(onlyExtraLarge) {
      width: 500px;
    }
  }
}
