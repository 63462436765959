@import 'src/styles/responsive';

.bookingSelection {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  width: 100%;
  height: 140px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%),
    var(--image-url), lightgray 50% / cover no-repeat;
  background-position: center;
  color: var(--white);
}

.bookingSkeleton {
  width: 100% !important;
  height: 140px !important;
  border-radius: 8px !important;
}
