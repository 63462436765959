@import 'src/styles/responsive';

.parent {
  &Submit {
    display: flex;
    column-gap: 32px;
    padding: 32px 0 0 0;

    @include device(smallOnly) {
      column-gap: 12px;
      justify-content: center;
    }
  }
}
