.parent {
  border-radius: 8px;
  overflow: hidden !important;

  &Border {
    border: 1px solid var(--light-02);
  }

  &Img {
    width: 100% !important;
  }

  &Content {
    padding: 8px 18px 8px 18px;
  }

  h3 {
    margin: 16px 0 0 0;
  }

  &SkeletonCenter {
    h3 {
      margin: 16px auto;
    }

    ul li:last-child {
      margin: 16px auto;
    }
  }
}
