@import 'src/styles/responsive';

.parent {
  width: 465px;

  @include device(extraMediumSmall) {
    text-align: center;
    width: 300px;
  }

  &Actions {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include device(smallOnly) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
  }

  &ActionButton {
    @include device(extraMediumSmall) {
      width: 100% !important;
    }
  }
}
