.guest-thumb-container {
  .card-container {
    width: 450px;
    cursor: pointer;

    .card {
      height: 250px;
      background-color: var(--white);
      border-radius: 8px;
      width: 100%;
      overflow: hidden;

      .image-container {
        overflow: hidden;
        height: 178px;
        position: relative;
      }

      .image {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.4s ease-out;

        &:hover {
          transform: scale(1.1);
          transition: 0.4s ease-in-out;
        }
      }

      .site-info-container {
        position: absolute;
        top: 16px;
        left: 16px;

        .name {
          color: var(--white);
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
          margin: 0;
        }

        .address {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          color: var(--white);
          width: 170px;
          margin: 0;
        }
      }
    }
  }
}
