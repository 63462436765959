.formTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-05);
  margin-top: 0;
}

.form {
  width: 100%;
}

.formSubmit {
  margin-top: 22px;
}
