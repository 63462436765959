.parent {
  border-radius: 12px;

  &Header {
    display: flex;
    border-bottom: 1px solid #ddd;
  }

  &HeaderElement {
    width: 33.33%;
    border: 1px solid #ddd;
  }

  &Skeleton {
    margin: 2px 0;
  }
}
