.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: var(--dark-04);
  background-color: var(--light-01);
  margin-bottom: 0;
  border-radius: 6px;
  padding: 3px 6px;
  &Preview {
    cursor: pointer;
    margin: 0 0 0 10px;
    img {
      margin: 0 0 -3px 0;
    }
  }
}
