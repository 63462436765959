.parent {
  &FormImageItems {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    align-items: center;
  }

  &FormRemoveButton {
    padding: 0 !important;
  }

  &FormSubmit {
    margin-top: 22px;
  }

  &FormDescription {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    margin: 0 0 16px 0;
  }

  &ContainerName {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    margin-bottom: 4px !important;
  }

  &ContainerLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-02);
    margin: 0 0 12px 0;
  }

  &FormPassword,
  &FormLicense {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &FormItems {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
}
