.upload {

  .upload-label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--dark-06);
    padding: 0 0 4px 0;
    text-transform: capitalize;
  }

  .ant-upload {
    padding: 0 !important;
  }

  .ant-upload.ant-upload-drag {
    background: transparent !important;
    border: 1px dashed var(--dark-01);
    border-radius: 10px;

    &:hover {
      border-color: var(--dark-01) !important;
    }
  }

  .upload-details {
    display: flex;
    column-gap: 24px;
  }

  .upload-content {
    display: flex;
    column-gap: 24px;
    row-gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 24px 16px 24px;
  }

  .upload-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .upload-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 8px;
    text-align: start;
    color: var(--dark-04);
  }

  .upload-title-select {
    color: var(--tertiary-100);
  }

  .upload-file-type,
  .upload-file-name,
  .upload-file-size {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    color: var(--dark-04);
  }

  .upload-info {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .upload-preview {
    color: var(--dark-04) !important;
  }

  .upload-dot {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    display: inline-block;
    background-color: var(--tertiary-100);
  }

  .upload-file-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 0 0;
  }

  .upload-file-name {
    font-weight: 500;
  }

  .upload-file-size {
    color: var(--tertiary-100);
  }

  .upload-size {
    display: flex;
    align-items: center;
    column-gap: 17px;
  }

  .upload-icon {
    width: 45px;
    height: 40px;
  }
}
