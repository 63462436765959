.ant-checkbox,
.ant-radio-inner {
  width: 24px !important;
  height: 24px !important;
}

.ant-radio-inner {
  border: 1px solid var(--secondary-20) !important;
}

.ant-radio-checked .ant-radio-inner {
  border: 6px solid var(--primary-100) !important;
}

.ant-radio-checked .ant-radio-inner::after {
  border-radius: 50%;
  transform: scale(0.8) !important;
  background-color: var(--secondary-100) !important;
}

.ant-checkbox:hover {
  border-color: transparent !important;
}

.ant-checkbox-disabled {
  opacity: 0.4;
}

.ant-table-wrapper {
  max-width: 100%;

  .ant-spin-nested-loading {
    width: fit-content !important;
    min-width: 100%;
    max-width: inherit;
  }
}

.ant-table-thead > tr > th {
  height: 47px;
}

.ant-table-sticky-holder {
  top: 64px !important;
}

.ant-table-body {
  overflow-x: auto;
}

.ant-table-scroll .ant-table-body {
  overflow-y: scroll;
}

.ant-checkbox-inner {
  width: inherit !important;
  height: inherit !important;
  border-radius: 4px !important;
  border: 1px solid var(--secondary-20) !important;
}

.ant-checkbox-inner::after {
  left: 25% !important;
  top: 45% !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: transparent !important;
  border: 1px solid #cbc2c0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border: transparent !important;
  background-color: var(--primary-100) !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 9px;
  height: 17px;
  border: 2px solid var(--secondary-100) !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

.ant-checkbox-checked::after {
  border: none !important;
}

.ant-modal {
  width: max-content !important;
}

.ant-modal-content {
  background: var(--light-01);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.16) !important;
  border-radius: 16px !important;
  padding: 20px 32px !important;
}

.ant-modal-close,
.ant-modal-footer {
  display: none;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border: 1px solid var(--secondary-40) !important;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.slick-slider {
  position: relative !important;
}

.ant-col .ant-form-item-explain-error {
  color: var(--tertiary-100);
}

.ant-select-selector {
  display: flex;
  align-items: center;
  height: 40px !important;
}

.ant-select-multiple .ant-select-selector {
  min-height: 40px !important;
  max-height: min-content;
}

.ant-select-selection-search {
  margin: 0;
  height: 100%;
}

.ant-select-selection-search-input {
  border: none !important;
  outline: none !important;
  width: 100% !important;
  height: 35px !important;
}

.ant-radio {
  margin-top: -3px !important;
}

.ant-radio-wrapper {
  align-items: center !important;
  margin: 0 0 0 0 !important;
}

.ant-radio-wrapper span:not(antd-radio) {
  width: 100%;
}

.ant-radio-wrapper span {
  padding: 0 !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-05);
  white-space: nowrap !important;
}

.ant-radio {
  width: 24px !important;
}

.ant-switch {
  min-width: 40px !important;
  height: 24px !important;
  border-radius: 100px !important;
  background-color: transparent !important;
  background-image: none !important;
  border: 1px solid var(--secondary-20) !important;
}

.ant-switch:not(.ant-switch-checked) .ant-switch-handle {
  left: 3px !important;
}

.ant-switch-checked {
  border-color: transparent !important;
  background-color: var(--primary-100) !important;
}

.ant-switch-checked .ant-switch-handle {
  right: 3px !important;
}

.ant-switch-handle {
  top: 3px !important;
  width: 16px !important;
  height: 16px !important;
}

.ant-switch-handle::before {
  background-color: var(--secondary-100) !important;
}

.ant-select-selection-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 18px !important;
  text-transform: capitalize !important;
  color: var(--light-06) !important;
}

.rc-virtual-list-holder-inner {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0 !important;
}

.ant-select-item-option-content {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 18px !important;
  color: var(--black) !important;
  padding: 0 !important;
}

.ant-select-item {
  display: flex;
  align-items: center;
}

.ant-pagination.ant-pagination-mini {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-table-pagination.ant-pagination {
  display: flex !important;
  align-items: center !important;
}

.ant-pagination-item:hover a {
  color: var(--tertiary-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next a {
  color: var(--tertiary-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev a {
  color: var(--tertiary-100) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next a[disabled] {
  color: var(--light-06) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev a[disabled] {
  color: var(--light-06) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-prev {
  margin-right: 26px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-next {
  margin-left: 26px !important;
}

@media only screen and (max-width: 576px) {
  .ant-table-pagination.ant-pagination {
    justify-content: end !important;
  }

  .ant-pagination-options {
    display: flex !important;
  }
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer {
  margin-left: 30px !important;
  height: 25px;
  width: 90px !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer .ant-select-selector {
  background-color: var(--white) !important;
  color: var(--tertiary-100) !important;
  padding: 4px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: 1px solid var(--primary-80) !important;
}

.ant-pagination.ant-pagination-mini
  .ant-pagination-options-size-changer
  .ant-select-selection-item {
  padding-right: 0 !important;
  line-height: 15px !important;
  color: var(--tertiary-80) !important;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-size-changer .ant-select-arrow {
  width: 20px !important;
  height: 25px !important;
  background-color: var(--primary-80) !important;
  background: url(../assets/icons/down-select-table.svg) no-repeat right var(--white);
  background-size: 75%;
  border-radius: 0px 6px 6px 0px;
  right: 0;
  top: 6px;
  background-position: center;
}

.ant-pagination.ant-pagination-mini
  .ant-pagination-options-size-changer
  .ant-select-arrow
  .anticon
  svg {
  fill: transparent;
}

.ant-pagination-item {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
  border: 1px solid var(--dark-04) !important;
  margin: 0 4px !important;
  height: 40px !important;
  width: 40px !important;
  line-height: 40px !important;
  font-weight: 400 !important;
}

.ant-pagination-item:not(.ant-pagination-item-active) a {
  margin: -1px 0 0 0 !important;
  color: var(--dark-04) !important;
}

.ant-pagination-item:not(.ant-pagination-item-active),
.ant-pagination-item:not(.ant-pagination-item-active) a {
  transition: background-color 0.5s ease;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: var(--tertiary-100) !important;
}

.ant-pagination-item:not(.ant-pagination-item-active):hover {
  background-color: var(--tertiary-40) !important;
  border-color: transparent !important;
}

.ant-pagination-item:not(.ant-pagination-item-active):hover a {
  color: var(--light-01) !important;
}

.ant-pagination-item-active {
  border: none !important;
  background-color: var(--primary-100) !important;
}

.ant-pagination-item-active a {
  color: var(--dark-04) !important;
}

.ant-pagination-item-active:hover a {
  color: var(--dark-04) !important;
}

.ant-empty {
  margin: 12px 0 12px 0 !important;
}

.ant-select-selection-item {
  line-height: 18px !important;
  color: var(--dark-06) !important;
  font-weight: 500 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent !important;
}

.ant-table-cell input {
  display: flex;
  padding: 12px 0 12px 12px;
  font-weight: 500;
  font-size: 12px;
  height: 30px;
  width: 100%;
  background: var(--white);
  border: 1px solid var(--light-04);
  border-radius: 6px;
  color: var(--light-06);
}

.ant-table-container .ant-table-header > tr,
.ant-table-container .ant-table-body > tr {
  height: 48px !important;
}

.ant-table-container .ant-table-thead > tr > th,
.ant-table-container .ant-table-body .ant-table-cell {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  color: var(--dark-04);
  vertical-align: middle;
}

.ant-table-thead {
  height: 45px !important;
}

.ant-table table .ant-table-row {
  height: 48px !important;
}

.ant-table-container {
  max-width: calc(100vw - 30px);
  overflow: auto;
}

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 0 9px !important;
}

.ant-table .ant-checkbox {
  margin-top: -3px;
}

.ant-table .ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
}

.reset-button,
.add-button {
  height: min-content !important;
  width: min-content !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  padding: 0 !important;
  background-color: initial !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--tertiary-100) !important;
  cursor: pointer;
}

.add-button {
  font-size: 12px !important;
  line-height: 15px !important;
}

.ant-tabs-nav {
  margin: 0 0 24px 0 !important;
}

.ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-01);
}

.ant-tabs-nav::before {
  border: none !important;
}

.ant-tabs-tab {
  padding: 0 0 8px 0 !important;
}

.ant-tabs-ink-bar {
  background: transparent !important;
}

.ant-tabs-ink-bar::after {
  content: '';
  position: absolute;
  left: 50%;
  right: 0;
  bottom: 0;
  background: var(--tertiary-100) !important;
  border-radius: 4px;
  height: 3px !important;
  width: 26px;
  transform: translateX(-50%);
}

.ant-popover-inner-content {
  padding: 8px 12px 8px 12px !important;
  height: max-content;
}

.table-select {
  max-width: 65px;
  height: 23px;
}

.ant-select-dropdown {
  padding: 0;
  box-shadow: 0 4px 4px rgba(78, 78, 90, 0.16);
  border-radius: 4px !important;
}

.table-select-content {
  min-width: min-content !important;
}

.ant-select-dropdown .rc-virtual-list-holder-inner {
  row-gap: 0;
  padding: 0;
}

.ant-select-item {
  padding: 8px 10px 8px 10px !important;
}

.table-select-content .ant-select-item,
.ant-pagination-options .ant-select-item {
  min-height: min-content;
  padding: 6px 8px 6px 8px !important;
}

.ant-select-dropdown .ant-select-item-option-active {
  background-color: var(--light-03);
}

.table-update-select .ant-select-selector {
  border-radius: 6px !important;
}

.ant-select-item-option-content {
  font-weight: 500;
  font-size: 14px !important;
  line-height: 20px !important;
  color: var(--dark-04) !important;
}

.table-select-content .ant-select-item-option-content,
.ant-pagination-options .ant-select-item-option-content {
  font-size: 12px !important;
  line-height: 15px !important;
}

.ant-select-selector {
  height: 40px !important;
}

.table-select .ant-select-selector {
  background-color: var(--secondary-40) !important;
  padding: 0 !important;
  border-radius: 6px !important;
  box-shadow: none !important;
  border: none !important;
}

.table-select .ant-select-selection-item {
  line-height: 15px !important;
  color: var(--white) !important;
  font-size: 12px !important;
  margin: 5px !important;
}

.table-select .ant-select-arrow {
  width: 20px !important;
  height: 23px !important;
  background-color: var(--primary-80) !important;
  background: url('../assets/icons/down-select-table.svg') no-repeat right;
  background-size: 75%;
  border-radius: 0 6px 6px 0;
  right: 0;
  top: 6px;
  background-position: center;
}

.table-select .ant-select-arrow .anticon svg {
  fill: transparent;
}

.ant-table-cell input {
  padding: 12px;
  color: var(--dark-05);
}

.table-update-select .ant-select-selection-item {
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-05);
}

.ant-table-column-sorter {
  color: var(--primary-100) !important;
}

.ant-table-column-sorters {
  float: left;
}

.ant-form-item-control {
  position: relative;
}

.ant-form-item-explain-error {
  /* position: absolute; */
  top: -6px;
  left: 10px;
  transition: all 0.1s;
  background-color: var(--white);
  color: var(--danger) !important;
  font-size: 12px;
}

.ant-tooltip-inner {
  color: var(--dark-05) !important;
  background-color: var(--white) !important;
  width: auto !important;
  border-radius: 8px !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px 8px 12px !important;
  z-index: 100;
}

.ant-tooltip-arrow {
  --antd-arrow-background-color: var(--white) !important;
}

.ant-input[disabled] {
  color: #272322 !important;
  font-weight: 500;
}

input:not([type='range']) {
  touch-action: auto !important;
}

.ant-notification-notice-close {
  top: 16px;
  right: 16px;
}

.ant-notification-notice {
  width: 552px;
  border: 0 !important;
}

.ant-notification-notice-message {
  font-style: normal;
  color: var(--white) !important;
  font-size: 16px;
  line-height: 24px;
}

.ant-notification-notice-message {
  font-weight: 600;
  margin: 0 0 6px 32px !important;
}

.ant-notification-notice-description {
  font-weight: 500;
  margin: 0 !important;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--white) !important;
}

.ant-notification-notice-icon {
  margin: 0;
}

.ant-notification-notice-wrapper {
  border-radius: 13px !important;
  overflow: hidden;
  border: 1px solid var(--dark-04) !important;
}

.ant-spin-dot {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid var(--dark-04);
}

.ant-spin-dot-item {
  display: none !important;
}

.ant-picker-ok .ant-btn {
  border: none;
  border-radius: 6px;
  color: var(--secondary-100);
  background-color: var(--primary-100);
}

.ant-picker-ok .ant-btn:disabled {
  background-color: var(--primary-20);
  color: var(--primary-40);
}

.ant-select-multiple {
  height: min-content !important;
}

.ant-radio-checked::after {
  border: none;
}

.ant-radio-wrapper {
  width: 100%;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-remove:active {
  color: var(--dark-01) !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--dark-04) !important;
}

[ant-click-animating-without-extra-node='true']::after {
  display: none;
}

.cart-space-vertical {
  width: 100% !important;
}

.custom-card {
  min-height: 70px !important;
}

.sq-card-message {
  margin: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.h-full {
  height: 100% !important;
}

.p-0 {
  padding: 0 !important;
}

.ant-spin-container {
  height: 100% !important;
}

.table-col-min-width {
  min-width: 100px !important;
}

.table-col-min-width-md {
  min-width: 60px !important;
}
