.parent {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--dark-06);
  margin: 0 0 2px 0;
  text-transform: capitalize;

  &Required {
    color: var(--warning);
  }
}
