.parent {
  // min-width: 260px;
  width: 100%;

  box-sizing: border-box;
  display: flex;
  height: 40px;
  border: 1px solid var(--light-04);
  border-radius: 6px;

  :global {
    .ant-select-arrow {
      margin-top: 0 !important;
    }
    .ant-select-selector:first-child {
      box-sizing: border-box !important;
      padding-right: 5px !important;
    }
    .ant-select-selector:last-child {
      box-sizing: border-box !important;
      padding-left: 5px !important;
    }
  }

  &IconContainer {
    color: var(--dark-01);
    padding: 0;
    font-weight: bold;
    display: flex;
    align-items: center;

    &Disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
      :global {
        .ant-select-arrow {
          display: none;
        }
      }
    }
  }
  .selectContainer {
    width: 100%;
    border-radius: 6px;
    outline: none;
  }
}
