.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  width: 100%;
  border-radius: 6px;
}

.ant-form-item-required {
  font-weight: 500;
  font-size: 13px;
}

.ant-col .ant-form-item-label {
  margin-bottom: 4px;
}

.ant-input {
  border-radius: 6px !important;
}

/* .ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.ant-checkbox + span {
  margin-top: 6px;
}
