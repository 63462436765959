@import '../../../styles/responsive';

.parent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &Title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    line-height: 129%;
    color: var(--dark-03);
  }

  &List {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 32px;
    @include device(smallOnly) {
      column-gap: 15px;
    }
  }

  &Item {
    display: flex;
    column-gap: 8px;
    font-weight: 500;
    line-height: 129%;
    color: var(--dark-04);
  }

  &Icon {
    width: 16px;
    height: 16px;
  }
}
