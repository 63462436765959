@import 'src/styles/responsive.scss';

.container {
  padding: 32px;
  background-color: var(--white);
  flex-grow: 1;
  @include device(extraMediumSmall) {
    padding: 16px;
  }
}

.sider {
  background-color: transparent;
}

.header {
  background-color: transparent;
  padding: 0 0 16px 0;
}

.mobileSelect {
  padding: 16px 0;
  background-color: var(--background-color);
}

.layout {
  background-color: transparent;
}
