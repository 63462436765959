@import 'src/styles/responsive';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  min-height: 120px;
  padding: 16px;

  &Large {
    height: max-content;
    @include device(stdSmUp) {
      max-height: 160px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 2;
  }

  &:hover {
    .headerImage {
      transform: scale(1.1);
      transition: 0.8s ease-in-out;
    }
  }

  @include device(largeOnly) {
    flex-direction: column;
    row-gap: 24px;
  }

  &Image {
    width: 100%;
    left: 0;
    right: 0;
    top: -100%;
    transition: 0.8s ease-in-out;
    position: absolute;

    @include device(smallOnly) {
      top: 0;
      object-fit: cover;
      height: 100%;
    }
  }

  &Content {
    height: 100%;
    min-height: 100%;
    z-index: 3;
  }

  &LeaseCode {
    font-weight: 700;
  }

  &CancelLease {
    padding: 0 32px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 15px;
    margin-top: 8px;
    height: fit-content !important;

    @include device(smallOnly) {
      padding: 0 16px !important;
    }
  }
}

.imageSkeleton {
  border-radius: 8px;
  overflow: hidden;
  width: 100% !important;
  min-height: 120px;

  &Large {
    min-height: 160px;
  }
}

.headerLeftSide {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include device(extraMediumSmall) {
    align-items: center;
  }

  &Title {
    font-weight: 400;
    color: var(--light-01);
    font-size: 24px;
    line-height: 32px;
    margin: 0 !important;

    @include device(smallOnly) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &SubTitle {
    font-weight: 600;
    color: var(--primary-100);
    font-size: 24px;
    line-height: 32px;
    margin: 0 !important;

    @include device(smallOnly) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &TitleBottom {
    font-weight: 600;
    color: var(--light-01);
    font-size: 24px;
    line-height: 32px;
    bottom: 0;
    margin: 0 0 16px 0 !important;
    @include device(stdLgUp) {
      position: absolute;
      margin: 0 !important;
    }
  }
}

.headerRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  @include device(extraMediumSmall) {
    align-items: center;
  }

  &Title {
    font-weight: 600;
    color: var(--light-01);
    font-size: 32px;
    line-height: 40px;
    margin: 0 !important;

    @include device(smallOnly) {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }
}
