.parent {
  cursor: pointer;
  position: relative;

  &Notification {
    position: absolute;
    left: 12px;
    bottom: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-05);
    background-color: var(--primary-100);
  }

  &Icon {
    width: 24px;
    height: 24px;
  }
}

.embeddedNotification {
  background-color: var(--tertiary-100);
  color: var(--white);
}
