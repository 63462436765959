.deposit-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .payment-status {
    text-transform: capitalize;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: var(--primary-100);
    color: var(--white);
    margin: 2px 0;
    text-align: center;
    width: max-content;
    &[data-status='succeeded'] {
      background-color: var(--success);
    }
    &[data-status='failed'] {
      background-color: var(--warning);
    }
  }

  .deposit-refund-container {
    max-width: 564px;
    background: white;
    border: solid 1px var(--light-03);
    padding: 24px 32px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .share-container {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }

    .share-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: solid 1px var(--light-03);
      padding: 4px 12px;
      &[data-status='2'] svg path {
        fill: var(--attention);
      }
      &[data-status='1'] svg path {
        fill: var(--success);
      }
      &[data-status='0'] svg path {
        fill: var(--warning);
      }
    }

    .deposit-money {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--dark-04);
      span {
        color: var(--tertiary-100);
      }
    }
    .request-button {
      color: var(--tertiary-100);
      cursor: pointer;
      font-size: 12px;
      line-height: 15px;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .deposit-notes {
    list-style: disc inside none;
    padding: 0;
    margin: 16px 0 0 0;
    li {
      color: var(--dark-04);
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
