.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin: 0;
  }

  &Price {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--dark-04);
    margin: 0;
  }
}
