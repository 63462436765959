@mixin device($breakpoint) {
  @if $breakpoint == extraSmall {
    @media only screen and (max-width: 500px) {
      @content;
    }
  }

  @if $breakpoint == extraMediumSmall {
    @media only screen and (max-width: 580px) {
      @content;
    }
  }

  @if $breakpoint == smallOnly {
    @media only screen and (max-width: 779px) {
      @content;
    }
  }

  @if $breakpoint == mediumOnly {
    @media only screen and (min-width: 780px) and (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint == extraSmallUp {
    @media only screen and (min-width: 442px) {
      @content;
    }
  }

  @if $breakpoint == mediumSmallUpOnly {
    @media only screen and (min-width: 590px) and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == mediumUp {
    @media only screen and (min-width: 780px) {
      @content;
    }
  }

  @if $breakpoint == extraLargeOnly {
    @media only screen and (max-width: 1000px) {
      @content;
    }
  }

  @if $breakpoint == largeUp {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == largeOnly {
    @media only screen and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint == largeUpOnly {
    @media only screen and (max-width: 1430px) {
      @content;
    }
  }

  @if $breakpoint == largeUpPlus {
    @media only screen and (min-width: 1240px) {
      @content;
    }
  }

  @if $breakpoint == onlyExtraLarge {
    @media only screen and (max-width: 1700px) {
      @content;
    }
  }

  @if $breakpoint == onlyExtraLargeUp {
    @media only screen and (max-width: 1850px) {
      @content;
    }
  }

  @if $breakpoint == stdXs {
    @media only screen and (max-width: 575px) {
      @content;
    }
  }

  @if $breakpoint == stdSm {
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint == stdMd {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint == stdLg {
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
    }
  }

  @if $breakpoint == stdXl {
    @media only screen and (min-width: 1200px) and (max-width: 1599px) {
      @content;
    }
  }

  @if $breakpoint == stdXxl {
    @media only screen and (min-width: 1600px) {
      @content;
    }
  }

  @if $breakpoint == stdSmUp {
    @media only screen and (min-width: 576px) {
      @content;
    }
  }

  @if $breakpoint == stdMdUp {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == stdLgUp {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint == stdXlUp {
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}

@function if-important($important) {
  @return #{if($important, '!important', '')};
}

$maxWidth: 1920;
$maxWidthContainer: 1170;

@mixin adaptive-value($property, $startSize, $minSize, $type, $important: false) {
  $addSize: $startSize - $minSize;
  @if $type==1 {
    @media (max-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(
          #{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320})
        )
        if-important($important);
    }
  } @else if $type==2 {
    @media (min-width: #{$maxWidthContainer + px}) {
      #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}))
        if-important($important);
    }
  } @else {
    #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}))
      if-important($important);
  }
}
