.parent {
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  border-radius: 8px;
  background-color: var(--light-01);
  overflow: hidden;

  &:hover {
    box-shadow: 0 4px 4px rgba(78, 78, 90, 0.16);
  }

  &Photo {
    width: 100%;
    min-height: 173px;
  }

  &Content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 16px 24px 16px;
  }

  &Name,
  &Price {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin-bottom: 4px;
  }

  &Description {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-04);

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &Price {
    color: var(--tertiary-100);
  }

  &Button {
    width: 150px;
    height: 40px;
    margin: 0 auto;
  }
}
