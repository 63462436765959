@import 'src/styles/responsive';

.parent {
  width: 100%;
  display: block;
  border-radius: 8px;

  &Room {
    background-color: var(--light-01);
    width: 100%;
  }

  &Summary {
    cursor: pointer;
    list-style-type: none;
    color: var(--dark-04);
    background-color: var(--light-03);
    border-radius: 8px;
    height: 64px;
    padding: 0 16px 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;

    &::before {
      height: 24px;
      width: 24px;
      content: url('../../../../../../../../assets/icons/summary-small.svg');
    }
  }

  &Room:nth-child(even) {
    background-color: var(--primary-20);
  }

  &Content {
    display: block;
    width: 100%;

    hr:last-child {
      display: none;
    }
  }

  &RoomName {
    font-weight: 700;
    font-size: 14px;
  }

  &Option {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 16px 19px;
  }

  &OptionLabel,
  &Summary {
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: #4e4e5a;
    font-size: 16px;
  }
  &Summary {
    width: 100%;
  }

  &OptionLabel {
    font-weight: 400;
  }

  &Options {
    max-width: 70%;
    text-align: end;
    font-size: 14px;
  }

  &RoomPhotos {
    gap: 9px;
    max-width: 60%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &RoomPhoto {
    position: relative;
    width: 127px;
    height: 109px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &RoomLabel {
    position: absolute;
    top: 8px;
    right: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--light-01);
    background: rgba(39, 35, 34, 0.4);
    border-radius: 4px;
    padding: 2px 2px 2px 2px;
  }

  &StillActive {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    border-radius: 4px;
    color: var(--dark-03);
    max-width: 391px;
    width: 100%;
    height: min-content;
    min-width: min-content;
    white-space: nowrap;
    text-align: center;
    padding: 12px 12px 12px 12px;
    background-color: var(--light-02);
  }
}

.parent[open] .parentSummary {
  background-color: var(--primary-100);

  &::before {
    transform: rotate(180deg);
  }
}
