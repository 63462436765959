.parent {
  width: min-content;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--light-01);
  border-radius: 16px;
  white-space: nowrap;
  background-color: var(--primary-100);

  &Large {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    padding: 4px 16px;
  }

  &Dark {
    color: var(--secondary-100) !important;
  }

  &Open,
  &Unpaid {
    background-color: var(--tertiary-100);
  }

  &Solved,
  &Resolved,
  &Completed,
  &Paid {
    background-color: var(--success);
  }

  &Closed,
  &Canceled,
  &CancelAuction,
  &UnsignedContracts,
  &RequestToComplete {
    background-color: var(--warning);
  }

  &PreDelinquent {
    background-color: var(--attention);
  }

  &Delinquent {
    background-color: var(--purple);
  }

  &Auction,
  &AuctionCompleted {
    background-color: var(--blue);
  }

  &InProgress,
  &Active {
    color: var(--white);
    background-color: var(--primary-100);
  }

  &Finished {
    background-color: var(--secondary-100);
    color: var(--light-01) !important;
  }
}
