@import 'src/styles/responsive';

.parent {
  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include device(extraMediumSmall) {
      width: inherit;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  &Rooms {
    padding: 7px 0 20px 0;
  }

  &RoomsTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--dark-04);
    margin: 0;
    padding: 0 0 16px 0;

    span:first-child {
      margin-right: 25px;
    }

    @include device(extraMediumSmall) {
      font-size: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}
