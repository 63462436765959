@import 'src/styles/responsive';

.parent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 12px 32px 32px 32px;
  min-height: 100%;

  @include device(extraMediumSmall) {
    padding: 12px 16px;
  }

  &Slots {
    display: flex;
    column-gap: 16px;

    @include device(onlyExtraLarge) {
      flex-direction: column;
      row-gap: 20px;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 380px;
    width: 100%;
    padding-top: 12px;
    row-gap: 12px;
    @include device(onlyExtraLarge) {
      max-width: 100%;
    }

    // this shit is from calendar in order to make it responsive
    @include device(onlyExtraLargeUp) {
      width: 436px;
      max-width: 100%;
    }

    @include device(extraMediumSmall) {
      max-width: 310px;
      width: 100%;
    }
  }

  &SelectedSlot {
    width: 100%;
    display: flex;
    gap: 24px;
    padding: 13px 0 0 0;
    flex-direction: column;
  }

  &SelectedDay {
    font-style: normal;
    font-weight: 600;
    margin: 0;
    color: var(--dark-05);
    font-size: 16px;
    line-height: 24px;
  }

  &BookingStatusDescription {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    color: var(--dark-04);
    margin: 24px 0 0 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  &BookingStatusQuarters {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }

  &BookingStatusIcon {
    scale: 2.6;
    margin-right: 10px;
  }

  &Options {
    width: 100%;
    display: flex;
    row-gap: 16px;
    flex-direction: column;
  }

  &Packages {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0 0;
  }

  &ProvisionTitle {
    font-weight: 600;
    color: var(--dark-05);
    margin: 0 0 16px 0;
    font-size: 16px;
    line-height: 24px;
  }

  &Inform {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--light-06);
    line-height: 15px;
    margin: 0 0 0 0;
  }
}

.discount {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary-100);
  font-family: 'Inter';
}

.overlayParent {
  width: 820px;

  @include device(onlyExtraLarge) {
    width: 500px;
  }

  @include device(extraMediumSmall) {
    width: 400px;
  }

  @include device(extraSmall) {
    width: 350px;
  }
}

.actionButtonsTop {
  width: 100%;
  display: none;
  @media (min-width: 1701px) {
    display: block;
  }
}

.actionButtonsBottom {
  width: 100%;
  display: none;

  @media (max-width: 1700px) {
    display: block;
  }
}
