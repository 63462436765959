@import 'src/styles/responsive';

.button {
  width: inherit !important;
  height: inherit !important;
  font-style: normal;
  border-radius: 6px !important;
  color: var(--secondary-100) !important;

  &Primary {
    border-color: transparent !important;
    background-color: var(--primary-100) !important;

    &:disabled {
      background-color: var(--primary-20) !important;
      color: var(--primary-40) !important;
      border-color: transparent !important;
    }

    &:hover {
      border-color: transparent !important;
    }
  }

  &Secondary {
    border: 1px solid var(--primary-100) !important;
    color: var(--secondary-100) !important;

    &:disabled {
      background-color: initial !important;
      border: 1px solid var(--primary-40) !important;
      color: var(--primary-40) !important;
    }
  }

  &Tertiary,
  &Tertiary80 {
    background-color: var(--secondary-100) !important;
    border: none !important;
    box-shadow: none !important;
    color: var(--white) !important;
    height: 30px !important;

    &:disabled {
      background-color: var(--tertiary-20) !important;
    }
  }

  &Tertiary80 {
    background-color: var(--tertiary-80) !important;
  }

  &Quaternary {
    background-color: var(--primary-100) !important;
    border-radius: 24px !important;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.25) !important;

    &:hover {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  &Calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    background-color: transparent;
    border: 1px solid #d9d9d9;
    max-width: 305px;

    &:hover {
      border-color: var(--dark-01) !important;
      box-shadow: none !important;
    }
  }

  &Default {
    background-color: transparent !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0;
  }

  &Small {
    height: 30px !important;
    padding: 0 24px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
  }

  &Medium {
    padding: 0 32px;
    width: min-content !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    height: 40px !important;

    @include device(smallOnly) {
      padding: 0 22px;
    }
  }

  &Large {
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    height: 40px !important;
    padding: 0 16px;
  }

  &Danger {
    background-color: var(--danger) !important;
    color: var(--white) !important;
  }

  &DangerOutline {
    color: var(--warning) !important;
  }

  &Success {
    background-color: transparent !important;
    border: 1px solid var(--success) !important;
    color: var(--success) !important;
  }

  &SuccessFilled {
    background-color: var(--success) !important;
    color: var(--white) !important;

    &:disabled {
      opacity: 0.5;
      background-color: var(--success) !important;
      color: var(--white) !important;
    }
  }

  &Spinner {
    height: 25px !important;
  }

  &Reset {
    height: min-content !important;
    width: min-content !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    padding: 0 !important;
    background-color: initial !important;
    border: none !important;
    box-shadow: none !important;
    color: var(--tertiary-100) !important;
  }
}
