.parent {
  width: inherit;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--light-04);
  background-color: var(--light-01);

  &Skeleton {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
  }

  &Medium {
    height: 40px;
  }

  &Small {
    height: 28px;
    border: 1px solid var(--light-06);

    div {
      padding: 0 8px 0 8px !important;
    }

    span {
      font-size: 12px !important;
    }

    img {
      width: 14px;
      margin: 2px -4px 0 0;
    }
  }

  div {
    border: none !important;
  }

  &Multiple &Skeleton {
    padding: 25px 0 0 0;
  }

  &Disabled {
    opacity: 0.4;
    border: 1px solid var(--light-04);
    background-color: var(--light-01);
  }

  :global {
    .ant-select-selection-item {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }
  }
}

.label {
  font-style: normal;
  font-weight: 500;
  color: var(--dark-06);
  margin-bottom: 0;
  padding: 0 0 4px 0;
  font-size: 13px;
  line-height: 20px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.loadingContainer {
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--light-04);
  background-color: var(--light-01);
}

.loadingSkeleton {
  width: 100% !important;
  height: 100% !important;
  padding: 0;
}
