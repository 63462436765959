@import 'src/styles/responsive';

.parent {
  z-index: 1000;
  display: flex;
  position: fixed;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.45);

  &Content {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin: 20px 0 20px 0;
    border-radius: 16px 0 0 16px;
    background-color: var(--light-01);
    box-shadow: -4px 0px 16px rgba(0, 0, 0, 0.16);


  }

  &Header {
    position: sticky;
    display: flex;
    flex-direction: column;
    background-color: inherit;
    top: -16px;
    z-index: 10;
    @include device(extraMediumSmall) {
      top: -10px;
    }
  }

  &HeaderContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 32px;
    @include device(extraMediumSmall) {
      padding: 10px 20px;
    }
  }

  &HeaderSeperator {
    margin: 0;
  }

  // &Children {
  //   padding: 0 32px 32px 32px;
  // }
  &Title {
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 40px;
    color: var(--dark-06);
    max-width: 500px;
    margin-top: 0 !important;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include device(smallOnly) {
      line-clamp: 3;
      font-size: 22px;
      line-height: 30px;
      max-width: 250px;
    }
  }

  &CloseIcon {
    height: 32px;
    @include device(smallOnly) {
      height: 24px;
    }
  }
}
