.parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  gap: 64px;
  border: 1px solid var(--light-02);

  &First {
    background-color: var(--white);
  }

  &Others {
    background-color: var(--primary-20);
  }

  &Title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0 !important;
    color: var(--dark-04);
    margin: 0;
  }

  &Description {
    font-size: 12px;
    font-weight: 500;
    color: var(--dark-04);
    margin-bottom: 0 !important;
  }
}
