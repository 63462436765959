@import 'src/styles/responsive';

.parent {
  &Calendar {
    padding-bottom: 12px;
    border-radius: 12px;
    background-color: white;
    position: relative;
  }

  &CalendarDisabled {
    pointer-events: none;

    & > * {
      pointer-events: auto;
    }
  }

  &Action {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }

  &ActionIcon {
    margin-right: 10px;
  }

  &EventTitle {
    font-weight: 600;
    margin-top: 4px;
    font-size: 16px;
    margin-bottom: 4px;
  }

  &ShortEventTitle {
    font-weight: 700;
    margin-top: 6px;
    font-size: 12px;
  }

  &EventStatus {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    margin-bottom: 16px;
  }

  &ShortEventStatus {
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    margin-top: -14px;
  }

  &ErrorSmsMessage {
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    color: var(--warning);

    @include device(smallOnly) {
      font-size: 12px;
    }
  }

  &EventContainer {
    position: relative;
    height: calc(100% + -5px);
    margin-top: -15px;
    margin-left: -6px;
    width: calc(100% + 13px);
    padding: 10px 8px;
  }

  &ShortEventContainer {
    position: relative;
    height: calc(100% + -5px);
    margin-top: -20px;
    margin-left: -6px;
    width: calc(100% + 13px);
    padding: 13px 8px;
  }

  &EventSms {
    position: absolute;
    right: 0;
    right: 8px;
    top: 12px;
  }

  &EventTemperature {
    font-weight: 600;
  }
}

.tooltip {
  font-size: 12px;
  font-weight: normal;

  &Title {
    font-size: 14px;
    font-weight: 500;
  }

  &ActionItem {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  &Icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}

.time {
  display: none;

  &:last-child {
    position: absolute;
    background-color: white;
    border: 1px solid var(--secondary-50);
    border-radius: 6px;
    display: block;
    width: 77px;
    color: var(--secondary-50);
    font-weight: 500;
    text-align: center;
    z-index: 3;
    left: 0;
  }
}

.parentCalendar {
  :global {
    .rbc-timeslot-group {
      min-height: var(--zoom-factor, 30px);
    }

    .rbc-time-slot {
      min-height: var(--zoom-factor, 30px);
    }

    .rbc-time-column {
      @include device(smallOnly) {
        min-height: calc(100vh - 600px);
      }
    }

    .rbc-label {
      padding: 0 2px;
    }

    // Ensure time gutter text stays consistent
    .rbc-time-gutter .rbc-timeslot-group {
      min-height: 30px; // Keep time labels at consistent size
    }

    .rbc-time-header {
      margin-right: 0 !important;
    }
  }
}

.minimapRoom {
  height: 100%;
  border-right: 1px solid var(--light-03);

  &:last-child {
    border-right: none;
  }
}
