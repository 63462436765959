@import 'src/styles/responsive';

.homeOngoingContainer {
  :global {
    .table-select {
      max-width: 200px;
      width: max-content;
      height: auto !important;
    }

    .ant-select-selection-item {
      margin: 8px 24px 8px 8px !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 24px !important;
    }

    .ant-select-arrow {
      height: 100% !important;
      padding: 0 10px !important;
      width: 32px !important;
    }

    .ant-select-item-option-content {
      font-size: 18px !important;
      line-height: 30px !important;
    }
  }
}

.noBookings {
  box-sizing: border-box;
  padding: 8px 12px;
  box-shadow: 0px 9px 28px 8px #0000000d;
  gap: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  width: max-content;
}
