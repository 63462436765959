@import 'src/styles/responsive';

.calendar {
  height: 432px;
  min-width: 376px !important;
  position: relative;
  animation: none !important;
  transition: none !important;

  @include device(extraMediumSmall) {
    height: max-content;
  }

  .ant-picker-year-btn,
  .ant-picker-month-btn {
    pointer-events: none !important;
  }

  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none !important;
  }

  @include device(onlyExtraLargeUp) {
    min-width: 100%;
  }

  @include device(extraMediumSmall) {
    min-width: 310px !important;
  }

  .ant-picker-cell-in-view .ant-picker-cell-selected .ant-picker-cell-inner:not(.currentActiveDay) {
    background-color: var(--light-03) !important;
    color: var(--dark-05) !important;
  }

  .ant-picker-year-panel,
  .ant-picker-decade-panel,
  .ant-picker-month-panel {
    width: inherit !important;
  }

  .ant-picker-year-panel {
    td {
      .ant-picker-cell-inner {
        min-height: 60px !important;
      }
    }
  }

  .ant-picker-month-btn:hover {
    color: var(--tertiary-100) !important;
  }

  .ant-picker-month-panel tbody tr td div {
    width: 75px !important;
    height: 75px !important;
  }

  .ant-picker-month-panel tbody tr td div:hover {
    color: var(--secondary-100);
    background-color: var(--tertiary-20) !important;
  }

  .currentMonth {
    color: var(--light-01) !important;
    background-color: var(--tertiary-100) !important;
  }

  // .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  //   background-color: var(--tertiary-100) !important;
  // }

  .ant-picker {
    display: none;
    height: 1px;
    padding: 0 !important;
    border: 0 !important;
  }

  .ant-picker-body {
    width: 100%;
    max-width: 436px !important;
    height: inherit;
    padding: 0 !important;
  }

  .ant-picker-content,
  .ant-picker-date-panel {
    width: inherit !important;
  }

  .ant-picker-dropdown {
    width: 376px;
    position: static !important;
    @include device(onlyExtraLargeUp) {
      width: 100% !important;
    }
  }

  .ant-picker-panel-container {
    width: 376px;
    border: none !important;
    overflow: unset !important;
    background-color: transparent !important;
    box-shadow: none !important;
    animation: none !important;
    transition: none !important;
    @include device(onlyExtraLargeUp) {
      width: 100% !important;
    }
  }

  .ant-picker-content thead tr th {
    min-width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);

    @include device(extraMediumSmall) {
      width: 30px !important;
      height: 30px !important;
    }
  }

  .ant-picker-content tbody tr,
  .ant-picker-content thead tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;
  }

  .ant-picker-content tbody tr {
    padding: 16px 0 0 0;

    @include device(extraMediumSmall) {
      padding: 8px 0 0 0;
    }
  }

  .ant-picker-header {
    display: flex;
    gap: 8px;
    padding: 0 !important;
    border: none !important;
  }

  .ant-picker-header-super-next-btn,
  .ant-picker-header-super-prev-btn {
    display: none;
  }

  .ant-picker-next-icon::before,
  .ant-picker-prev-icon::before {
    width: 10px !important;
    height: 10px !important;
  }

  .ant-picker-header-prev-btn {
    right: 100px !important;
  }

  .ant-picker-cell-inner {
    min-width: 40px;
    height: 40px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    border-radius: 50% !important;
    background-color: var(--light-03);
    color: var(--dark-05);
    font-weight: 500;
    border-radius: 50%;
    @include device(extraMediumSmall) {
      width: 40px !important;
      height: 40px !important;
      font-size: 13px;
    }
  }

  .ant-picker-cell-disabled {
    border-radius: 50% !important;
    background-color: var(--light-02) !important;

    div {
      &:not(.currentActiveDay) {
        color: var(--light-05) !important;
      }
    }
  }

  .ant-picker-footer {
    display: none;
  }

  .ant-picker-cell {
    min-width: 40px !important;
    padding: 0 !important;
    border-radius: 50%;
    @include device(extraMediumSmall) {
      //width: 30px !important;
      min-width: unset !important;
    }

    &::before {
      width: fit-content !important;
    }
  }

  .ant-picker-cell-today .ant-picker-cell-inner::before,
  .ant-picker-panel {
    border: none !important;
  }

  .ant-picker-header-view {
    order: -1;
    display: flex;
    padding: 0 8px 8px 8px;
    align-items: flex-start;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
  }

  .ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner {
    background-color: var(--light-02) !important;
  }
}

.ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
  pointer-events: none;
  background-color: var(--light-02) !important;
  border-radius: 50%;

  .ant-picker-cell-inner {
    background-color: transparent;
  }

  div {
    &:not(.currentActiveDay) {
      color: var(--light-05) !important;
    }
  }
}

.day {
  background-color: var(--light-03) !important;
  color: var(--dark-05) !important;
  font-weight: 500;

  &:hover {
    color: var(--secondary-100);
    background-color: var(--tertiary-20) !important;
  }
}

.activeDay {
  color: var(--secondary-100);
  box-shadow: 0 4px 4px rgba(61, 57, 56, 0.12);
  background-color: var(--primary-100) !important;
}

.ant-picker-input {
  display: none !important;
}

.ant-picker {
  border: none;
  padding: 0;
}

.ant-picker-cell-disabled::before {
  background-color: transparent !important;
}

.currentActiveDay {
  color: var(--light-01) !important;
  background-color: var(--tertiary-100) !important;

  &:hover {
    background-color: var(--tertiary-100) !important;
  }
}

.booking-availability::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.booking-availability::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.booked-empty {
  border: 1.6px dashed var(--dark-05);
}

.booked-quarter::before {
  clip-path: polygon(50% 0%, 50% 50%, 0% 50%, 0% 0%);
  border: 1.6px solid var(--dark-05);
}

.booked-quarter::after {
  clip-path: polygon(100% 50%, 100% 100%, 50% 100%, 0% 100%, 0% 50%, 50% 50%, 50% 0%, 100% 0%);
  border: 1.6px dashed var(--dark-05);
}

.booked-half::before {
  clip-path: polygon(100% 50%, 100% 0%, 50% 0%, 50% 50%, 50% 100%, 100% 100%);
  border: 1.6px solid var(--dark-05);
}

.booked-half::after {
  clip-path: polygon(50% 50%, 50% 0%, 0% 0%, 0% 50%, 0% 100%, 50% 100%);
  border: 1.6px dashed var(--dark-05);
}

.booked-three-quarters::before {
  clip-path: polygon(100% 0%, 50% 0%, 50% 53.75%, 0% 53.75%, 0% 100%, 100% 100%);
  border: 1.6px solid var(--dark-05);
}

.booked-three-quarters::after {
  clip-path: polygon(0% 50%, 50% 50%, 50% 0%, 0% 0%);
  border: 1.6px dashed var(--dark-05);
}

.booked-full {
  border: 1.6px solid var(--dark-05);
}
