@import 'src/styles/responsive';

.parent {
  width: 465px;
  @include adaptive-value(width, 300, 280, 30);

  &Text {
    margin-bottom: 24px;
    @include adaptive-value(margin-bottom, 24, 16, 1);
  }

  &Actions {
    display: flex;
    justify-content: space-between;

    @include device(smallOnly) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  &ActionsButton {
    @include device(smallOnly) {
      width: 100% !important;
    }
  }
}
