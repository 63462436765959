@import '../../../../styles/responsive';

.parent {
  &Container {
    display: flex;
    gap: 10px;
    box-sizing: content-box;
    margin: 10px 0;
  }

  &Photo {
    min-width: 107px;
    height: 69px;
    border-radius: 8px;
    @include device(extraMediumSmall) {
      width: 100%;
      object-fit: cover;
    }
  }

  &MoreInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &Head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &Edit {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    color: var(--tertiary-100);
  }

  &Delete {
    cursor: pointer;
    margin-left: 10px;
  }

  &Type {
    width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &Type,
  &Price {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin: 0;
  }

  &Price {
    display: inline;
  }
}
