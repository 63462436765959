@import 'src/styles/responsive';

.parent {
  &Title {
    font-style: normal;
    font-weight: 600;
    color: var(--dark-05);
    margin: 0;
    font-size: 32px;
    line-height: 40px;
  }

  &Description {
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
    color: var(--dark-05);
    margin: 8px 0 16px 0;
  }

  &Documents {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: center;

    @include device(largeUpOnly) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
