.parent {
  &Container {
    border: 1px solid var(--light-04);
    border-radius: 6px;
    overflow: hidden;
  }

  textarea {
    border: none;
    outline: none;
    width: 100%;
    padding: 12px;
    display: block;
    background-color: #fff;

    resize: none;
    min-height: 140px;
    max-height: 200px;

    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: var(--light-06);
    }
  }
}
