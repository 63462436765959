.parent {
  padding: 16px 32px 16px 0;
  margin-right: -32px;
  height: calc(100vh - 550px);
  min-height: 250px;
  overflow: auto;

  &SkeletonItem {
    display: flex;
    flex-direction: column;
    background-color: var(--light-02);
    border-radius: 8px;
    overflow: hidden;
  }

  &SkeletonContent {
    padding: 25px 25px 15px 25px;
  }

  &SkeletonHead {
    width: 100%;
    background-color: var(--light-03);
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    padding: 25px 25px 15px 25px;
    margin: 0 0 15px 0;
  }

  &SkeletonHeadSecond {
    background-color: var(--border-color1);
  }

  &SkeletonHead h3 {
    width: 20% !important;
  }
}
