.containerName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-05);
  margin-top: 0;
}

.containerLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--dark-02);
  margin: 0 0 12px 0;
}

.formSubmit {
  margin-top: 22px;
}
