.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.groupContainer {
  border: 1px solid var(--dark-01);
  background: #e0d0ca33;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 8px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: border 0.2s ease;
  &[data-expanded='true'] {
    border: 1px solid var(--tertiary-100);
  }
  &[data-is-disabled='true'] {
    opacity: 0.5;
  }
  &[data-has-expand='false'] {
    cursor: default;
  }
}

.groupTitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--secondary-100);
  user-select: none;
}

.arrowIcon {
  color: var(--dark-04);
  transition: transform 0.2s ease;

  &Expanded {
    transform: rotate(180deg);
  }
}

.groupContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.discountsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 11px;
  border: 1px solid var(--primary-100);
  border-radius: 8px;
  position: relative;
  margin-top: 18px;
  gap: 8px;
}

.discountIcon {
  position: absolute;
  top: -22px;
  left: -12px;
}

.discountLabel {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--tertiary-100);
  text-align: center;
  white-space: pre-line;
}

.selectAllCheckbox {
  :global {
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: var(--tertiary-100) !important;
      width: 24px !important;
      height: 24px !important;
      transform: translate(-50%, -50%) !important;
      clip-path: polygon(0 0, 100% 100%, 0 100%) !important;
      left: 50% !important;
      top: 50% !important;
      border-radius: 4px !important;
    }
  }
}

.iconButton {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 40px;
}

.hvacSurcharge {
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-04);
}
