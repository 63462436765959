@import '../../../../styles/responsive';

.parent {
  width: 100%;
  display: grid;
  column-gap: 32px;
  row-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  @include device(extraMediumSmall) {
    grid-template-columns: none;
  }
}
