.parent {
  margin-top: 16px;
  thead th {
    background-color: var(--white) !important;
    // white-space: nowrap;
  }

  tbody td {
    white-space: nowrap;
    // width: fit-content;
  }

  &RowManager {
    display: flex;
    column-gap: 8px;
  }

  :global {
    .ant-form-item-control-input {
      .ant-select-single,
      .ant-select-selector {
        height: 30px !important;
      }
    }

    .table-select {
      .ant-select-single,
      .ant-select-selector {
        cursor: pointer !important;
        height: 23px !important;
      }
    }

    .ant-pagination-options .ant-select {
      .ant-select-selector {
        cursor: pointer !important;
        height: 25px !important;
      }
    }

    .ant-select-selection-search input {
      display: none !important;
    }

    .ant-table-cell {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 15px !important;
      align-items: center !important;
      color: var(--dark-04) !important;
      vertical-align: middle !important;
    }

    .ant-form-item-explain {
      white-space: normal !important;
    }
  }
}

.tableCellPhone {
  height: 28px !important;

  :global {
    input {
      border: none !important;
    }
  }
}
