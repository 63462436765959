.parent {
  position: absolute;
  left: 0;
  right: 0;
  top: 11px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  background-color: var(--tertiary-20);
  z-index: 10;

  &Spinner {
    width: 100%;
    text-align: center;
  }

  &List {
    padding: 60px 13px 16px 16px;
  }

  &Details {
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 16px 16px;
    background-color: var(--tertiary-40);
  }

  &Faq {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: var(--secondary-80);

    &:hover {
      color: var(--secondary-100);
    }
  }
}
