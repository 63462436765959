.promotion-notification-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .notification-paginator {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}
