.parent {
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 16px;
  padding: 0 0 0 11px;

  &StatusLabel,
  &PointsLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--light-01);
  }

  &PointsList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  &ProgressBar {
    box-sizing: content-box;
    border-width: 0 0 0 2px;
    border-style: solid;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
  }

  &ActiveStatus &StatusLabel,
  &ActiveStatus &PointsLabel {
    color: var(--tertiary-100);
  }

  &ActiveIcon {
    width: 16px;
    height: 16px;
    position: absolute;
    left: -18px;
  }

  &CheckedStatus &StatusLabel,
  &CheckedStatus &PointsLabel {
    color: var(--tertiary-80);
  }

  &NeighborStatus &StatusLabel,
  &NeighborStatus &PointsLabel {
    color: var(--tertiary-40);
  }
}
