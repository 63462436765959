@import 'src/styles/responsive.scss';

.parent {
  &FormDescription {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    margin: 16px 0 0 0;
  }

  &FormChild {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &FormItems {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &Manage {
    display: flex;
    gap: 24px;
    justify-content: flex-end;

    @include device(extraSmall) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &DeleteHidden {
    visibility: hidden;

    @include device(extraSmall) {
      display: none;
    }
  }

  &Items {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 32px 0 0 0;

    @include device(extraSmall) {
      flex-direction: column-reverse;
    }
  }

  &Delete {
    button {
      border: 1px solid var(--warning) !important;
      color: var(--warning) !important;
      padding: 0 32px !important;
    }
  }
}

.buttonsContainer {
  margin-top: 36px;
}
