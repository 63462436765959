.parent {
  width: 100%;

  &ErrorInform {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: var(--tertiary-100);
  }
}
