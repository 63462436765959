.parent {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  min-width: 100%;

  &TermsAndConditions {
    overflow-y: auto;
    padding: 64px 32px;
  }
}
