.parent {
  display: flex;
  column-gap: 22px;
  flex-wrap: wrap;
  row-gap: 10px;

  &RadioItem {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &RadioItemContent {
    display: flex;
    column-gap: 8px;
    height: 24px;
    align-items: center;
  }

  &Label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    white-space: nowrap;
    padding: 3px 0 0 0;
  }

  &ErrorInform {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: var(--tertiary-100);
  }
}
