.PhoneInput {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.PhoneInputInput {
  border: none;
  outline: none;
  flex: 1;
  height: 100%;
  padding: 0 12px;
  font-weight: 500;
}

.PhoneInput input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--light-06);
}

.PhoneInput--disabled {
  color: var(--light-06);
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.PhoneInputBorderOff {
  height: 30px;
}

.PhoneInputBorderOff:hover {
  outline: 0;
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  transition: all 0.3s;
}

.PhoneInputBorderOff .PhoneInputInput {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.ant-form-item-has-error .PhoneInput {
  border-color: #ff4d4f;
}

.PhoneInput--disabled input {
  background-color: transparent;
  cursor: not-allowed;
}

.errorMessage {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  margin-bottom: 0;
  color: var(--tertiary-100);
}
