.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.p-0 {
  padding: 0 !important;
}