@import '../../../../styles/responsive';

.roomThumbnail {
  position: relative;
  display: flex;
  row-gap: 24px;
  min-height: fit-content;
  flex-direction: column;
  background: var(--white);
  border: 1px solid var(--light-02);
  border-radius: 4px;
  overflow: hidden;
  transition: 0.8s ease-in-out;

  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--light-01);
    opacity: 0.5;
    z-index: 1;
  }

  // &Button {
  //   width: 100%;
  //   height: 40px;
  //   position: absolute;
  //   animation-name: buttonClose;
  //   animation-duration: 0.3s;
  //   opacity: 0;
  //   transition: 0.3s opacity;
  // }

  &Carousel {
    @include device(extraMediumSmall) {
      width: 100%;
    }
  }

  &:hover {
    transition: 0.4s ease-in-out;
    box-shadow: 0 4px 16px rgba(78, 78, 90, 0.1);
  }

  &:hover &PhotoNextButton {
    left: 15px;
    z-index: 1;
    transition: 0.6s z-index;
    animation-name: slickNextOpen !important;
    animation-duration: 0.6s !important;
  }

  &:hover &PhotoPrevButton {
    right: 15px;
    z-index: 1;
    transition: 0.6s z-index;
    animation-name: slickPrevOpen !important;
    animation-duration: 0.6s !important;
  }

  &:hover &Photo {
    transform: scale(1.1);
    transition: 0.4s ease-in-out;
  }

  // &:hover &Button {
  //   opacity: 1;
  //   top: 16px;
  //   animation-name: buttonOpen;
  //   animation-duration: 0.5s;
  // }
}

.roomThumbnailPhotoContainer {
  height: 218px;
  overflow: hidden;
  position: relative;
  border-radius: 4px 4px 0 0;
}

.roomThumbnailDisabled {
  cursor: default;
  opacity: 0.3;
}

.roomThumbnailInfo {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
}

.roomThumbnailPhoto {
  width: 413px;
  height: 218px;
  object-fit: cover;
  transition: 0.4s ease-in-out;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}

.roomThumbnailTitle {
  display: inline-block;
  width: fit-content;
  margin: 0;
  padding: 0 0 4px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--tertiary-100);
  cursor: pointer;
}

.roomThumbnailDescription {
  margin: 0;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-04);
}

.roomThumbnailIncluded {
  position: relative;
  padding: 16px 0 0 0;

  // &Hide {
  //   .hideList {
  //     visibility: hidden;
  //   }
  // }
}

.roomThumbnailPhotoPrevButton,
.roomThumbnailPhotoNextButton {
  cursor: pointer;
  position: absolute;
  top: 97px;
}

.roomThumbnailPhotoNextButton {
  left: -25px;
  animation-name: slickNextClose !important;
  animation-duration: 0.6s !important;
  z-index: -1;
  transition: 0.6s z-index;
}

.roomThumbnailPhotoPrevButton {
  right: -25px;
  animation-name: slickPrevClose !important;
  animation-duration: 0.6s !important;
  z-index: -1;
  transition: 0.6s z-index;
}

@keyframes buttonClose {
  0% {
    top: 16px;
  }
  100% {
    top: 200px;
  }
}

@keyframes buttonOpen {
  0% {
    top: 200px;
  }
  100% {
    top: 16px;
  }
}

@keyframes slickNextOpen {
  0% {
    left: -25px;
  }
  100% {
    left: 15px;
  }
}

@keyframes slickNextClose {
  0% {
    left: 15px;
  }
  100% {
    left: -25px;
  }
}

@keyframes slickPrevOpen {
  0% {
    right: -25px;
  }
  100% {
    right: 15px;
  }
}

@keyframes slickPrevClose {
  0% {
    right: 15px;
  }
  100% {
    right: -25px;
  }
}
