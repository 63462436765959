@import '../../../../styles/responsive';

.parent {
  &Title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 21px;
    color: var(--dark-05);
    word-wrap: break-word;
  }

  &Messages {
    @include device(mediumUp) {
      padding-right: 32px;
    }
  }

  &SkeletonTitle h3 {
    height: 30px !important;
  }
}
