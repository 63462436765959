@import 'src/styles/responsive';

.media-slide {
  width: 427px;
  height: 327px;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 32px 16px 0;
  position: relative;
  background-image: url('../../../../../assets/Display.svg');

  @include device(smallOnly) {
    width: 300px;
    height: 229px;
  }
}

.media-upload-skeleton {
  width: 390px !important;
  height: 290px !important;

  @include device(smallOnly) {
    width: 280px !important;
    height: 209px !important;
  }
}

.media-disabled {
  pointer-events: none;
  opacity: 0.9;
}

.media-label {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-04);
  margin: 0 0 7px 0;
}

.media-slide .ant-upload.ant-upload-drag {
  border: none;
  width: 398px;
  height: 296px;
  margin: 0 auto;
  background-color: var(--light-01);

  @include device(smallOnly) {
    width: 280px;
    height: 209px;
  }
}

.media-upload .ant-upload-list-picture-card-container,
.media-upload .ant-upload-select-picture-card {
  margin: 0 4px 4px 0;
}

.media-upload .ant-upload-select-picture-card,
.media-upload-item,
.media-upload .ant-upload-list-picture-card-container {
  height: 72px;
  width: 100%;
}

.media-upload .ant-upload-list {
  display: flex !important;
  flex-wrap: wrap;
}

.ant-upload-picture-card-wrapper {
  width: 427px !important;

  .ant-upload {
    &.ant-upload-select {
      height: 82px !important;
    }
  }
}

.media-upload-title,
.media-upload-description {
  white-space: nowrap;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--dark-05);
  margin: 0 0 6px 0 !important;
}

.media-upload-description {
  font-weight: 400;
}

.media-upload-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  border: 3px solid var(--primary-100);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.16));

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    z-index: 2;
  }
}

.media-upload-image {
  width: 104px;
  height: 66px;
  object-fit: cover;
}

.media-uploaded-image {
  width: 390px;
  height: 290px;
  object-fit: contain;

  @include device(smallOnly) {
    width: 280px;
    height: 209px;
  }
}

.media-upload .ant-upload-select-picture-card {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  order: -1;
}

.media-remove {
  top: 5px;
  right: 5px;
  height: min-content;
  position: absolute;
  z-index: 10;
}

.media-carousel {
  width: 390px;

  @include device(smallOnly) {
    width: 278px;
  }
}

.remove-icon {
  border-radius: 4px;
  background-color: var(--light-03);
  opacity: 0.85;
  border: 1px solid var(--primary-100);

  img {
    width: 22px;
    opacity: 1;
  }
}
