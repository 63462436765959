:root {
  /* Primary Colors */
  --primary-100: #eed382;
  --primary-80: #ecd796;
  --primary-60: #ebdbaa;
  --primary-40: #e8ddbd;
  --primary-20: #fff7e2;

  /* Secondary Colors */
  --secondary-100: #63382c;
  --secondary-80: #7d5b51;
  --secondary-60: #977e76;
  --secondary-40: #b19f9b;
  --secondary-20: #cbc2c0;

  /* Tertiary Colors */
  --tertiary-100: #cb7d61;
  --tertiary-80: #d0927c;
  --tertiary-60: #d6a795;
  --tertiary-40: #dabbb0;
  --tertiary-20: #e0d0ca;

  /* Quaternary */
  --quaternary-100: #3a4e42;
  --quaternary-80: #647069;
  --quaternary-60: #8b958e;
  --quaternary-40: #b2b8b3;
  --quaternary-20: #d9dcd9;

  /* Grayscale - Light */
  --light-01: #ffffff;
  --light-02: #f2f2f7;
  --light-03: #e5e5ea;
  --light-04: #d1d1d6;
  --light-05: #c7c7cc;
  --light-06: #aeaeb2;

  /* Grayscale - Dark */
  --dark-01: #a9a7a7;
  --dark-02: #939190;
  --dark-03: #7d7b7a;
  --dark-04: #524f4e;
  --dark-05: #3d3938;
  --dark-06: #272322;

  /* System Colors */
  --success: #7eb781;
  --attention: #f4c86e;
  --warning: #f67167;
  --white: #ffffff;
  --black: #000000;
  --danger: #f67167;
  --purple: #b288f7;
  --blue: #45a3d9;
  --site: #3a4e42;
  --background: #f9f9f9;
}
