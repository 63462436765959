.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;

  &Label {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--dark-06);
  }

  &ErrorInform {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: var(--tertiary-100);
  }
}
