.parent {
  display: flex;
  flex-direction: column;
  background-color: var(--light-02);
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 16px 0;

  &Name,
  &Head,
  &Message {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin: 0;
  }

  &Head {
    box-sizing: border-box;
    width: 100%;
    background-color: var(--light-03);
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &HeadActive {
    background-color: var(--secondary-20);
  }

  &Message,
  &Head {
    padding: 16px 16px 16px 16px;
    word-wrap: break-word;
  }

  &LastMessage {
    animation-name: active-message;
    animation-duration: 7s;
    transition-timing-function: ease-out;
  }
}

@keyframes active-message {
  0% {
    background-color: var(--secondary-20);
  }
  33% {
    background-color: var(--primary-100);
  }
  66% {
    background-color: var(--secondary-20);
  }
  100% {
    background-color: var(--primary-100);
  }
}
