.ant-result {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: var(--main-background);

  .ant-result-icon {
    margin: 0;
  }
}
