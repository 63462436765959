.parent {
  &Title {
    margin: 0 0 32px 0;
  }

  &LeaseType {
    padding: 4px 12px;
    border: 1px solid var(--primary-100);
    border-radius: 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-100);
    margin-left: 8px;
    &[data-promotion='true'] {
      color: var(--dark-04);
      border: 1px solid var(--success);
    }
  }
}
