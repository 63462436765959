@import 'src/styles/responsive';

.parent {
  width: 100%;
  display: block;
  border-radius: 8px;

  &Summary {
    cursor: pointer;
    list-style-type: none;
    color: var(--dark-04);
    background-color: var(--light-03);
    border-radius: 8px;
    height: 64px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    &::before {
      height: 24px;
      width: 24px;
      content: url('../../../../../../../../assets/icons/summary-small.svg');
    }
  }

  &Content {
    width: 100%;
    display: block;
    background-color: var(--white);

    hr:last-child {
      display: none;
    }
  }

  &TimeInterval {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    box-sizing: border-box;
  }

  &IdentifierLabel {
    font-size: 16px;
    font-weight: 400;
  }

  &Identifier {
    font-size: 16px;
    font-weight: 600;
  }

  &Option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 19px;
    width: 100%;
    box-sizing: border-box;
  }
}

.parent[open] .parentSummary {
  background-color: var(--primary-100);

  &::before {
    transform: rotate(180deg);
  }
}
