.parent {
  display: flex;
  position: relative;

  &Icon {
    top: 8px;
    left: 7px;
    width: 20px;
    z-index: 1;
    position: absolute;
    border: 0.5px solid var(--dark-04);
  }

  &Skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }

  div div {
    height: 30px;
    width: 30px !important;
    visibility: hidden;
    background-color: transparent !important;
  }
}
