.parent {
  position: relative;

  &Dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin: 0 10px 8px 2px;
    background-color: var(--success);
  }
}
