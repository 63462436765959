.menu {
  width: 100%;
  height: 40px;
}

:global {
  .ant-select-selection-item a,
  .ant-select-item-option-content a {
    color: var(--secondary-100);
  }
}
