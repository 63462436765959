@import 'src/styles/responsive';

.parent {
  &Name {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include device(extraSmall) {
      max-width: 100px;
    }
  }

  &Staff {
    color: var(--dark-01);
  }
}
