@import '../../../../styles/responsive';

.parent {
  &PaymentMethodTitle {
    padding: 0 0 24px 0;
  }

  &PaymentMethod {
    padding: 24px 0 0 0;
    width: calc(50% - 16px);
    @include device(smallOnly) {
      width: 100%;
    }
  }

  &Save {
    padding: 32px 0 0 0;
  }

  &PaymentMethodTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    margin-bottom: 0;
  }

  &ErrorInfo {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--warning);
    margin: 8px 0 0 35px;
  }
}
