.parent {
  cursor: pointer;
  padding: 0 !important;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 15px;
  color: var(--tertiary-100) !important;
  background-color: transparent !important;
  box-shadow: none;

  &:focus,
  &:hover {
    border: none !important;
    outline: none !important;
    color: var(--secondary-80) !important;
  }

  &:active {
    opacity: 0.5;
    transition: 0.2s ease-out;
  }
}
