@import 'src/styles/responsive';

.promotion-calendar-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px var(--light-02);
  border-radius: 6px;
  padding: 8px 0;
  gap: 8px;
  height: 100%;
  width: 260px;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-05);
    padding: 0;
    margin: 0;
  }

  .week-days {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    background-color: #e4f2e4;
    border-radius: 12px;
    color: #477648;
    padding: 4px 8px;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    width: max-content;
  }

  .weekend-days {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    background-color: #f1ecfe;
    border-radius: 12px;
    color: #562acb;
    padding: 4px 8px;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    width: max-content;
  }
}
