@import '../../../../styles/responsive';

.parent {
  width: 100%;
  display: flex;
  row-gap: 8px;
  flex-direction: column;

  &Title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
    font-family: 'Inter';
  }

  &Content {
    width: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
  }

  &Details[open] &Summary {
    background-color: var(--primary-100);
  }

  &Details &SummaryError {
    background-color: var(--tertiary-20);
  }

  &Details[open] &SummaryError {
    background-color: var(--tertiary-40);
  }

  &Details {
    width: inherit;
    max-width: 270px;

    @include device(extraMediumSmall) {
      max-width: 280px;
    }
  }

  &Slot {
    margin: 2px 0 0 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-04);
  }

  &Details &Summary::before {
    width: 16px;
    height: 16px;
    content: url('../../../../assets/icons/details-status.svg');
    transform: rotate(180deg);
  }

  &Details[open] &Summary::before {
    transform: rotate(0deg);
  }

  &RemoveButton {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 0 0 0;
  }

  &Summary {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
    margin-bottom: 0;
    background-color: var(--light-03);
    padding: 3px 8px 3px 8px;
    border-radius: 2px;
    cursor: pointer;

    display: flex;
    list-style-type: none;
    flex-direction: row-reverse;
    justify-content: space-between;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &Options {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 8px 0 0 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-04);
  }

  &RoomRemoveIcon {
    width: 16px;
    height: 16px;
  }
}
