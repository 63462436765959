@import 'src/styles/responsive';

.parent {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--light-04);
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: center;

  &[data-shadow='true'] {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  @include device(smallOnly) {
    width: 30px;
    height: 30px;
  }
}

.image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;

  @include device(smallOnly) {
    width: 30px;
    height: 30px;
    &[data-is-limited='true'] {
      width: 50px;
      height: 50px;
    }
  }
}
