.parent {
  position: absolute;
  left: 0;
  right: 0;
  top: 11px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  z-index: 10;
  background-color: var(--light-01);

  &Content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
    padding: 45px 13px 16px 16px;
  }

  &ContentItem {
    position: relative;
    display: block;
    font-weight: 500;
    line-height: 18px;
    font-size: 14px;
    cursor: pointer;
    color: var(--dark-04);
    &[data-validate-identity='true'] {
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: -3px;
        right: -10px;
        border-radius: 50%;
        background-color: var(--danger);
      }
    }
  }

  &ContentItem:hover {
    color: var(--tertiary-40) !important;
    transition: 0.3s ease-in-out;
  }
}
