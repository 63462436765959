.parent {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;

  &Label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    margin-top: 5px;
  }

  &ErrorInform {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: var(--tertiary-100);
  }
}
