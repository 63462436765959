.parent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-top: 8px;
  padding-left: 16px;
  margin-right: -24px;

  &Content {
    display: flex;
    justify-content: space-between;
  }
}
