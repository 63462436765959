.parent {
  :global {
    .ant-input-prefix {
      margin: 0 !important;
    }
  }

  .container {
    box-sizing: border-box;
    border: 1px solid #d1d1d6;
    border-radius: 6px;
    overflow: hidden;
    height: 40px;

    &Skeleton {
      height: 40px !important;
      width: 100% !important;
    }

    input {
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      padding: 0 12px 0 12px;
      font-weight: 500;

      &::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--light-06);
      }
    }
  }

  .container:has(input:disabled) {
    opacity: 0.4;
    border: 1px solid var(--light-04);
    background-color: #f5f5f5;
    cursor: not-allowed !important;
  }
}
