.headerLinksContainer {
  background-color: transparent !important;
  border-bottom: 0 !important;
  justify-content: flex-end;
  align-items: center;
  flex: auto;
  min-width: 0;

  :global {
    .ant-menu-horizontal {
      border-bottom: none;
    }

    .ant-menu-overflow-item .ant-menu-title-content {
      transition-duration: 0s !important;
      color: var(--white) !important;
      &:hover {
        color: var(--primary-100) !important;
      }
    }

    .ant-menu-item-group .ant-menu-item-group-title a {
      color: var(--black) !important;
      &:hover {
        color: var(--primary-100) !important;
      }
    }

    .anticon-ellipsis {
      color: var(--white) !important;
      transform: scale(2);
      border: 0.5px solid var(--white) !important;
      border-radius: 2px;
      &:hover {
        border: 0.5px solid var(--primary-100) !important;
        color: var(--primary-100) !important;
      }
    }
    .ant-menu-submenu-horizontal:not(.ant-menu-overflow-item-rest)::after,
    .ant-menu-item:not(.ant-menu-overflow-item-rest)::after {
      width: 24px !important;
      margin: auto !important;
      border-radius: 10px;
    }

    .ant-menu-overflow-item-rest::after {
      display: none !important;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      // color: var(--white) !important;
      position: relative;

      &:hover,
      &-active,
      &-selected {
        color: var(--primary-100) !important;
        background-color: transparent !important;
      }
    }

    .ant-menu-submenu-popup {
      .ant-menu-item {
        color: var(--black) !important;

        a {
          color: var(--black) !important;
        }

        &:hover,
        &-active,
        &-selected {
          background-color: var(--primary-20) !important;

          a {
            color: var(--primary-100) !important;
          }

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.overflowedIndicatorPopupClassName {
  background-color: red;
}
