.parent {
  box-sizing: border-box;
  &MessageManager {
    display: flex;
    column-gap: 16px;
  }

  &MessageContentItem {
    padding: 4px 0 0 0;
  }
}

.parent,
.parentClosed {
  width: 100%;
  padding: 16px 16px 16px 16px;
  background-color: var(--primary-100);
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 16px;
  line-height: 24px;
  color: var(--dark-04);
}

.parentClosed {
  cursor: unset;
  justify-content: center;
}

.parentActive {
  padding: 13px 16px 13px 16px;
}
