.title {
  margin: 0 0 24px 0;
}

.react-grid-item.react-grid-placeholder {
  border-radius: 12px;
}

.rbc-day-slot {
  background-color: var(--light-01) !important;
}

.rbc-event {
  padding: 8px !important;
  border: none !important;
}

.rbc-event:focus {
  outline: none !important;
}

.rbc-event-label {
  white-space: pre;
}

.rbc-time-view .rbc-row {
  height: 100%;
}

.rbc-timeslot-group {
  border: none !important;
}

.rbc-time-slot {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark-03);
}

.rbc-time-view,
.rbc-time-content,
.rbc-header {
  border-right: 1px solid var(--light-03) !important;
  border-bottom: none !important;
  border-left: none !important;
  border-top: none !important;
  background-color: var(--light-01);
}

.rbc-label.rbc-time-header-gutter {
  min-width: auto !important;
  z-index: 99;
}

.rbc-row {
  min-height: 0 !important;
}

.rbc-current-time-indicator {
  height: 2px !important;
  background-color: #ec6b4a !important;
}

.rbc-time-view-resources .rbc-time-gutter {
  border-right: 1px solid var(--light-03) !important;
}

.rbc-time-header-content {
  border-left: 1px solid var(--light-03) !important;
}

/* RES-1744 */

.rbc-events-container {
  border-left: 1px solid var(--light-03) !important;
  background-image: radial-gradient(7px 4px at bottom, var(--light-06) 1.5px, transparent 2px);
  background-position: 0 0, 0 0;
  background-size: 8px 40px;
}

.rbc-disabled-event {
  width: calc(100% + 10px) !important;
  margin-left: -1px !important;
}

.rbc-time-view {
  border-radius: 12px;
  position: relative;
}

.rbc-time-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: sticky;
  top: 64px;
  z-index: 15;
}

.rbc-time-view-resources .rbc-time-header-content {
  flex: 0 !important;
}

/* .rbc-time-view-resources .rbc-time-header {
  overflow-x: scroll;
  scrollbar-width: none;
} */

.rbc-time-view-resources .rbc-time-header::-webkit-scrollbar {
  display: none;
}

.rbc-time-content {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  overflow-y: hidden;
}

.rbc-addons-dnd .rbc-event {
  margin-left: 5px;
}

.rbc-allday-cell {
  display: none;
}

.rbc-fixed-event {
  cursor: default !important;
}

.rbc-time-header-content {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.rbc-time-header-gutter {
  border-bottom: 1px solid var(--light-03);
}

.rbc-addons-dnd-resize-ns-anchor {
  scale: 1.2;
  z-index: 10 !important;
}

.rbc-time-gutter {
  z-index: 11 !important;
}
