.parent {
  width: calc(100% - 8px);
  display: flex !important;
  flex-direction: column;
  row-gap: 13px !important;

  &Disabled {
    opacity: 0.7;
  }
}
