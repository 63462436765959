.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &Content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
    display: flex;
    align-items: center;
    column-gap: 15px;
    word-break: break-all;
  }

  &SecretContent {
    padding: 7px 0 0 0;
  }

  &StatusIcon {
    width: 24px;
    height: 24px;
  }
}
