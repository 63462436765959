.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  &Message {
    font-size: 24px;
    line-height: 32px;
    margin-top: 24px;
    color: var(--dark-06);
    font-weight: 600;
    line-height: 32px;
    text-align: center;
  }
}
