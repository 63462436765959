@import 'src/styles/responsive';

.parent {
  width: 480px;
  z-index: 100;
  height: min-content;
  background-color: var(--light-03);
  padding: 26px 32px 24px 24px;
  box-sizing: border-box;
  border-radius: 8px;

  &Load {
    background-color: transparent;
  }

  @include device(largeOnly) {
    width: 100%;
    display: block;
    margin: 0 0 0 0;
    border-radius: 8px;
  }

  &Title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--dark-05);
    margin: 0 !important;
  }

  &Loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &Content,
  &Loading {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px 0 8px 0;
  }

  &ContentItem {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &FooterDescription {
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
    color: var(--dark-05);
    margin: 0;
  }

  &ContentItemDescription {
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-between;
  }

  &ContentItemTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin-bottom: 0;
  }

  &StatusIcon {
    width: 24px;
    height: 24px;
  }

  &ContentItemLabel,
  &ContentItemValue {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
  }

  &ContentItemValue {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    font-weight: 500;
    white-space: nowrap;
  }

  &Ambient {
    padding: 32px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &FullWidth {
    width: 100% !important;
  }
}
