h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: var(--dark-05);
  margin: 0;
}

h1.ant-typography {
  font-size: 38px;
  font-weight: 400;
  line-height: 44px;
  font-family: 'Inter', sans-serif;
}

h2.ant-typography {
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  font-family: 'Inter', sans-serif;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}