@import '../../../../../../../styles/responsive';

.parent {
  height: 20px;

  &Name {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include device(extraSmall) {
      max-width: 100px;
    }
  }
}
