.minimap {
  position: fixed;
  bottom: 50px;
  right: 32px;
  width: 160px;
  height: 50px;
  background-color: var(--light-01);
  border-radius: 8px;
  display: flex;
  cursor: grab;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  z-index: 998;
  user-select: none;
}

.viewport {
  position: absolute;
  top: 8px;
  height: calc(100% - 20px);
  border-radius: 4px;
  border: 3px solid var(--tertiary-100);
  pointer-events: none;
  margin-left: 10px;
  z-index: 999;
}
