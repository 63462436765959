@import 'src/styles/responsive';

.sidebar {
  text-align: center;
  min-width: 100px;
  background-color: var(--light-01);
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, 0.15);
  min-height: 100%;
  padding-top: 64px;
  position: fixed;
  z-index: 21;

  &[data-guest='true'] {
    display: none;
  }

  @include device(extraMediumSmall) {
    min-width: 90px;
    // top: 64px;
  }
}

.sidebarNavigation {
  padding: 24px 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.navigationItem {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  height: 76px;
  color: var(--secondary-100);
  position: relative;

  &[data-disabled='false'] {
    &:hover {
      color: var(--secondary-80);
    }
  }
}

.disabledRoute {
  color: var(--light-06);
  cursor: not-allowed !important;
  pointer-events: none;
}

.activeNavigationItem {
  &:hover {
    color: var(--light-06);

    &[data-disabled='false'] {
      color: var(--secondary-80);
    }
  }

  &.activeNavigationItem:not(:first-child):hover {
    color: var(--secondary-100);
  }
}

.sidebarNavigation > .activeNavigationItem:first-child:hover {
  color: var(--secondary-100);
}

.sidebarNavigation > .activeAdminItem:not(:first-child):hover {
  color: var(--tertiary-100);
}
