@import 'src/styles/responsive';

.promotion-room-availability-wrapper {
  @include device(extraMediumSmall) {
    .ant-modal-content {
      width: calc(100vw - 20px) !important;
    }
    .ant-modal-body {
      padding: 16px !important;
    }
  }

  .promotion-room-availability {
    min-width: min(400px, 90vw);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include device(extraMediumSmall) {
      width: 100%;
      min-width: 100%;
    }

    .footer-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-container {
      border: 1px solid var(--light-02);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 16px;
      border-radius: 4px;
      width: 100%;
      padding-bottom: 16px;

      .time-slot-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        box-sizing: border-box;
        padding: 0 41px;
      }

      .room-header {
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        overflow: hidden;
        height: min-content;
        min-height: 64px;
        padding: 8px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
          z-index: 2;
        }

        &:hover {
          .image {
            transform: scale(1.1);
            transition: 0.8s ease-in-out;
          }
        }

        @include device(largeOnly) {
          flex-direction: column;
          row-gap: 24px;
        }

        .content-container {
          display: flex;
          z-index: 3;
          justify-content: space-between;
          flex-direction: column;
          height: 100%;
          width: 100%;
          flex-grow: 1;
        }

        .image {
          width: 100%;
          left: 0;
          right: 0;
          top: -100%;
          transition: 0.8s ease-in-out;
          position: absolute;

          @include device(smallOnly) {
            top: 0;
          }
        }

        .title {
          font-style: normal;
          font-weight: 500;
          color: var(--light-01);
          margin-bottom: 0;
          font-size: 20px;
          line-height: 28px;

          @include device(smallOnly) {
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    width: 100%;

    .room-skeleton {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--light-02);
      border-radius: 4px;
      overflow: hidden;
      gap: 16px;
      padding-bottom: 16px;

      .room-skeleton-img {
        overflow: hidden;
        width: 100% !important;
        max-height: 64px;
      }

      .room-skeleton-timeslot {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 16px;

        .checkbox-skeleton {
          min-width: 24px !important;
          width: 24px !important;
          height: 24px;
          border-radius: 6px;
        }

        .input-skeleton {
          border-radius: 6px;
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}
