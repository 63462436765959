.mailbox-container {
  .title {
    color: var(--dark-05);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .section-margin {
    margin-top: 16px;
    &[data-extra='true'] {
      margin-top: 24px;
    }
  }
  .ant-checkbox {
    top: 0;
  }
  .ant-checkbox-wrapper {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--dark-05);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
