.parent {
  height: 40px !important;
  border-radius: 6px !important;
  width: 100%;
}
.container {
  width: 100%;
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  min-width: 298px;
}
