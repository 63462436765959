.parent {
  display: flex;
  min-height: 100%;
  align-items: center;
  flex-direction: column;
  padding: 52px 36px 36px 36px;
  background-color: var(--light-01);
  position: relative;

  &Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 108px 0 0 0;
  }

  &Logo {
    width: 227px;
    height: 113px;
  }

  &ValidateButton {
    width: 189px;
    height: 40px;
    margin: 32px 0 0 0;
  }

  &Title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--dark-06);
    margin-bottom: 0;
    padding: 24px 0 16px 0;
  }

  &Description {
    max-width: 580px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    text-align: center;
    margin-bottom: 0;
  }

  &HomeButton {
    margin-top: 20px;
  }
}
