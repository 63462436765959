.parent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &Title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin: 0;
  }
}
