.searchable-select-input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-select-single {
    height: auto !important;
  }

  .input-container {
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--light-04);
    background-color: var(--light-01);
  }

  .input-wrapper {
    height: 40px !important;

    .ant-input-prefix {
      margin-left: 11px;
    }

    input {
      overflow: hidden;
      text-overflow: ellipsis !important;
      white-space: nowrap;
    }
  }
}

.searchable-dropdown-container {
  align-items: center;
  min-height: 100px;
  max-height: 400px;
  overflow: hidden auto;
  padding: 24px;
  row-gap: 16px;
  display: grid;
  grid-template-columns: 1fr;

  .ant-radio {
    margin: 0 !important;
  }

  .ant-radio-wrapper {
    align-items: start !important;
  }

  .radio {
    gap: 8px;

    p {
      white-space: pre-wrap;
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .code {
    color: var(--dark-05);

    &[data-custom='true'] {
      color: var(--tertiary-100);
    }
  }
}
