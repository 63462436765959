@import 'src/styles/responsive';

.searchWrapper {
  margin-bottom: 16px;

  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.searchInput {
  width: 100%;
  @include device(stdMdUp) {
    width: 350px;
  }
}

.searchImg {
  margin-left: 12px;
  margin-right: -4px;
}

.resetFilter {
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--tertiary-100);
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.addButton {
  margin-bottom: 18px;

  @media (min-width: 576px) {
    margin-bottom: 16px;
  }
}
