@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.widget {
  &Container,
  &ContainerCollapsed {
    position: fixed;
    right: 32px;
    animation: fadeIn 0.5s ease-out, shake 0.5s ease-out;
  }

  &Container {
    bottom: 130px;
    width: 224px;
    z-index: 20;
  }

  &ContainerCollapsed {
    bottom: 135px;
    z-index: 20;
    box-sizing: border-box;
  }

  &TogglerCollapsed,
  &TogglerExpanded {
    background-color: var(--primary-100);
    border-radius: 50%;
    position: absolute;
    right: -5px;
    z-index: 2;
    padding: 3px 3px 0;
    cursor: pointer;
    border: 1px solid var(--dark-04);
    box-sizing: border-box;
  }

  &TogglerCollapsed {
    top: -10px;
  }

  &TogglerExpanded {
    top: -5px;
  }

  &CartContainer,
  &CartContainerCollapsed {
    position: relative;
    color: var(--white);
    background-color: var(--quaternary-80);
    cursor: pointer;
    box-shadow: 0 4px 4px 0 #00000040;
  }

  &CartContainer {
    text-align: center;
    border-radius: 15px;

    :global {
      .ant-select-selector {
        background-color: var(--primary-100) !important;
      }
    }
  }

  &CartContainerCollapsed {
    padding: 20px;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    box-sizing: border-box;

    span {
      color: var(--white);
      background-color: var(--tertiary-100);
    }
  }

  &Amount {
    border-radius: 12px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 14px;

    p {
      margin: 0 !important;
      font-size: 16px;
      font-weight: 500;
      color: var(--secondary-80);
    }
  }

  &Cart {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
