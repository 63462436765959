@import '../../../../../styles/responsive';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

.header {
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  min-height: 120px;
  padding: 16px 16px 16px 16px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    z-index: 2;
  }

  &:hover {
    .headerImage {
      transform: scale(1.1);
      transition: 0.8s ease-in-out;
    }
  }

  @include device(largeOnly) {
    flex-direction: column;
    row-gap: 24px;
  }

  &Image {
    width: 100%;
    left: 0;
    right: 0;
    top: -100%;
    transition: 0.8s ease-in-out;
    position: absolute;

    @include device(smallOnly) {
      top: 0;
    }
  }

  &Content {
    z-index: 3;
  }
}

.imageSkeleton {
  border-radius: 8px;
  overflow: hidden;
  width: 100% !important;
  min-height: 120px;
}

.headerLeftSide {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include device(extraMediumSmall) {
    align-items: center;
  }

  &Title {
    font-weight: 400;
    color: var(--light-01);
    margin-bottom: 0;
    font-size: 24px;
    line-height: 32px;
    margin: 0;

    @include device(smallOnly) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &SubTitle {
    font-weight: 600;
    color: var(--primary-100);
    font-size: 24px;
    line-height: 32px;
    margin: 0;

    @include device(smallOnly) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.headerRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;

  @include device(extraMediumSmall) {
    align-items: center;
  }

  &Title {
    font-weight: 600;
    color: var(--light-01);
    font-size: 32px;
    line-height: 40px;
    margin: 0 !important;

    @include device(smallOnly) {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }
}
