@import '../../../../styles/responsive';

.promotion-contract-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-style: normal;
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: var(--dark-06);
    margin-bottom: 16px;
  }

  .arrow-button {
    width: 64px;
    height: 64px;
    border-radius: 6px;
    background-color: var(--light-02);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .main-content {
    width: 100%;
    height: 100%;
    width: min(80vw, 1000px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
    margin: 0 auto;
    box-sizing: border-box;

    @include device(smallOnly) {
      width: 70vw;
    }

    @include device(extraMediumSmall) {
      width: 90vw;
    }

    .content {
      border: 1px solid var(--dark-02);
      border-radius: 16px;
      padding: 32px 24px;
    }

    .contract-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 800px;

      .lease-code {
        color: var(--tertiary-100);
        margin: 0;
      }
    }

    .contract-content {
      padding: 16px 40px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: white;
    }

    .footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 16px;
      background-color: var(--light-02);
      padding: 32px;
      row-gap: 24px;

      @include device(smallOnly) {
        padding: 16px 12px;
        margin-bottom: 50px;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0 !important;
    align-items: flex-start;
  }

  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-05);
    gap: 8px;
    margin: 0;
  }

  .high-payment {
    font-weight: 700;
  }

  .sign-wrapper {
    @include device(smallOnly) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .ant-checkbox-wrapper {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--dark-05);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-checkbox {
    top: 0;
  }

  .promotion-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding: 32px;
    background-color: white;
    border-radius: 14px;
    border: 3px solid var(--primary-100);

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: var(--dark-06);
      margin: 0 !important;
      @include device(smallOnly) {
        text-align: center;
      }
    }

    .description {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--dark-04);
      margin: 0 !important;
      @include device(smallOnly) {
        text-align: justify;
      }
    }
  }

  .start {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .end {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

  .skeleton-footer {
    border-radius: 16px;
    height: 100px;
    width: 100%;
  }
}
