@import 'src/styles/responsive';

.parent {
  &AddButton {
    margin-bottom: 18px;

    @include device(stdXs) {
      margin-bottom: 16px;
    }
  }
}
