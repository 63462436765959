@import '../../../../styles/responsive';

.parent {
  width: 646px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include device(smallOnly) {
    width: 480px;
  }

  @include device(extraMediumSmall) {
    width: 380px;
  }

  @include device(extraSmall) {
    width: 300px;
  }

  &FormSubmit {
    display: flex;
    column-gap: 24px;
    margin-left: auto;
  }

  &Description {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    font-size: 16px;
  }
}
