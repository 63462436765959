@import '../../../../styles/responsive';

.locationTitle {
  color: var(--dark-06);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  margin: 0 0 24px 0;
}

.locationSkeleton {
  width: 100%;
  display: grid;
  column-gap: 32px;
  row-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

  @include device(extraMediumSmall) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    column-gap: 16px;
    row-gap: 8px;
  }
}
