@import 'src/styles/responsive';

.promotion-details-rooms-container {
  .calendar-skeleton {
    width: 100% !important;
    height: 140px !important;
    border-radius: 8px !important;
    overflow: hidden !important;

    span {
      width: inherit !important;
      height: inherit !important;
    }
  }

  .rooms-skeleton {
    width: 100% !important;
    height: 450px !important;
    border-radius: 8px !important;
    overflow: hidden !important;

    span {
      width: inherit !important;
      height: inherit !important;
    }
  }

  .mobile-date-range {
    display: none;
    gap: 8px;
    @include device(smallOnly) {
      display: block;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: var(--tertiary-100);
      font-weight: 600;
    }
  }

  .rooms-container-mobile {
    display: none;
    @include device(smallOnly) {
      display: flex;
      gap: 8px;
      width: 100%;
      flex-direction: column;
    }

    .mobile-card {
      width: 100% !important;
      background-color: var(--white) !important;
    }
  }

  .rooms-main-container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .calendar-container {
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rooms-container {
      display: flex;
      gap: 8px;
      overflow-x: auto;
      overflow-x: hidden;

      @include device(smallOnly) {
        display: none;
      }
    }

    .arrow-wrapper {
      min-width: 100px;
      display: flex;
      gap: 8px;
      flex-direction: row;
      align-items: center;

      &[data-end] {
        flex-direction: row-reverse;
      }

      .date-range {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: var(--dark-05);
      }
    }
  }

  .arrow-container {
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: var(--light-02);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    path {
      fill: var(--dark-05);
    }

    &[data-rotate] {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      cursor: not-allowed;
      background-color: var(--light-03);

      path {
        fill: var(--dark-03);
      }
    }
  }

  .month-view-button {
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
    }
  }
}

.month-view {
  .ant-popover-inner-content {
    display: none !important;
  }
}
