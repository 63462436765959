@import 'src/styles/responsive';

.parent {
  display: flex;
  column-gap: 32px;

  @include device(largeOnly) {
    flex-direction: column;
    row-gap: 24px;
  }

  &Wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }

  &LeaseDetails {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: calc(100vw - 690px);

    @include device(largeOnly) {
      max-width: 100%;
    }
  }

  &NotificationPaginator {
    gap: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
