@import 'src/styles/responsive';

.promotion-details-container {
  display: flex;
  gap: 16px;
  box-sizing: border-box;
  &[data-col='true'] {
    flex-direction: column;
  }

  @include device(largeOnly) {
    flex-direction: column;
    row-gap: 24px;
  }

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}
