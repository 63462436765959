@import '../../../../styles/responsive';

.parent {
  display: flex;
  column-gap: 8px;
  height: 40px;

  &Container {
    width: 100%;
    height: inherit;
  }

  &Content,
  &SelectedSlot {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    column-gap: 4px;
    color: var(--secondary-100);
  }

  &SelectedSlot {
    padding: 0 16px 0 16px;
  }

  &SelectedSlot {
    height: 40px;
    border-radius: 6px;
    font-style: normal;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border: 1px solid var(--tertiary-100);
  }

  &SelectedSlotActive {
    color: var(--light-01);
    background-color: var(--tertiary-100);
  }

  &ContentDisabled {
    color: var(--primary-40);
  }

  &ContentDisabled &Price {
    color: var(--dark-01);
  }

  &Price {
    color: var(--dark-05);
    font-size: 14px;
  }
}
