.parent {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-flow: column;

  input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--light-06);
  }

  &Field {
    width: 100%;
    height: 40px;
    border: 1px solid #d1d1d6;
    border-radius: 6px;
  }

  &ErrorInform {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: var(--tertiary-100);
  }
}
