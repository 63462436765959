.parent {
  background-color: var(--light-02);
  height: calc(100vh - 64px);
  margin: -16px -32px -16px 0px !important;
  padding: 26px 24px 26px 24px;

  &InfoTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--dark-05);
    margin: 0 0 0 0;
  }

  &DetailsTitle {
    margin: 0 0 8px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
  }

  &InfoDetails {
    padding: 16px 0 16px 0;
    border-bottom: 1px solid var(--light-03);
  }

  &Status {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &DetailsDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
    margin: 0 0 0 0;
  }
}
