.parent {
  min-height: 100vh;
  height: fit-content;
  position: relative;
  padding: 52px 36px 36px 36px;
  background-color: var(--light-01);

  &ErrorInform {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    color: var(--warning);
  }

  &Content {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 560px;
    margin: 0 auto;
  }

  &Title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: var(--dark-06);
    margin-bottom: 0;
    padding: 0 0 16px 0;
  }

  &Form {
    width: 100%;
  }

  &Section,
  &SectionInfo {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 0 0 32px 0;
  }

  &SectionInfo {
    padding: 0 0 16px 0;
  }

  &InfoDetails {
    display: flex;
    column-gap: 30px;
  }

  &SectionTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px !important;
    color: var(--dark-04);
    margin-bottom: 0;
  }

  &Logo {
    font-weight: 500;
    width: 227px;
    height: 113px;
    margin: 0 0 43px 0;
  }

  &Description,
  &ModalTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark-04);
    margin-bottom: 0;
  }

  &ModalTitle {
    white-space: nowrap;
  }

  &Upload {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 68px;
    padding: 24px 0 0 0;
  }

  &ModalWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px 0 10px;
    row-gap: 19px;
  }

  &Submit {
    display: flex;
    justify-content: flex-end;
  }

  &CenteredButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
  }
}
