.parent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  &FormItem {
    display: grid;
    column-gap: 32px;
    grid-template-columns: repeat(2, 1fr);
  }
}
