@import 'src/styles/responsive';

.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 50px);
  height: 64px;
  background-color: var(--site);
  padding: 0 32px 0 32px;
  column-gap: 10px;
  position: fixed !important;
  top: 0;
  z-index: 22;

  @include device(extraMediumSmall) {
    padding: 0 16px 0 16px;
    width: calc(100% - 30px);
  }

  &BurgerMenu {
    padding: 0 12px 0 0 !important;
  }

  &BurgerMenuActive {
    padding: 0 12px 0 18px !important;
  }

  &HamburgerLines {
    height: 16px;
    width: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &HamburgerLines &HamburgerLine {
    display: inline-block;
    width: 100%;
    height: 2px;
    border-radius: 10px;
    background-color: var(--white);
  }

  &HamburgerLines &HamburgerLine1 {
    transform-origin: 0 0;
    transition: transform 0.4s ease-in-out;
    transform: rotate(0deg);
  }

  &HamburgerLines &HamburgerLine2 {
    transition: transform 0.2s ease-in-out;
  }

  &HamburgerLines &HamburgerLine3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
    transform: rotate(0deg);
  }

  &BurgerMenuActive &HamburgerLine1 {
    border-radius: 5px;
    transform: rotate(38deg);
  }

  &BurgerMenuActive &HamburgerLine2 {
    transform: scaleY(0);
  }

  &BurgerMenuActive &HamburgerLine3 {
    border-radius: 5px;
    transform: rotate(-38deg);
  }

  &BackDoctor {
    background-color: transparent;
    margin-left: 15px;
    height: auto !important;
  }

  &SidebarLogo {
    cursor: pointer;
    width: auto;
    height: 30px;
  }

  &Content {
    display: flex;
    align-items: center;
    column-gap: 24px;
    justify-content: flex-end;
    flex: 1;
    width: 50%;

    @include device(smallOnly) {
      column-gap: 12px;
    }
  }

  &Name {
    font-weight: 700;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;

    &[data-admin='true'] {
      color: var(--primary-100);
    }
  }

  &Sticky {
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.15);
  }

  &Welcome {
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: white;
    margin-left: 16px;
    display: none;
    @include device(mediumUp) {
      display: block;
    }
  }
}

.loginButton {
  padding: 0 !important;
  height: auto !important;
}

.loginButtonLink {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
