.payment-history {
  .payment-status {
    text-transform: capitalize;
    padding: 4px 8px;
    border-radius: 16px;
    background-color: var(--primary-100);
    color: var(--white);
    margin: 2px 0;
    text-align: center;
    width: max-content;
    &[data-status='succeeded'] {
      background-color: var(--success);
    }
    &[data-status='failed'] {
      background-color: var(--warning);
    }
  }
}
