@import 'src/styles/responsive';

.parent {
  &Disabled {
    opacity: 0.9;
  }

  &Skeleton {
    padding: 7px 0 0 50px;
  }

  &Select {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px !important;
    border-radius: 6px;
    border: 1px solid var(--light-04);
    background-color: var(--light-01);
    position: relative;

    div {
      height: 40px;
      width: inherit;
      border: none;
      background-color: transparent !important;
    }

    span {
      margin: 2px 0 0 30px !important;
    }

    span img {
      margin: -14px 0 0 0;
      z-index: 10;
    }
  }

  &SelectSecondary {
    background-color: var(--light-02);
    border: none;
    border-radius: 8px;
  }

  &Dropdown {
    width: 266px;
    margin: auto 0 0 -30px;
  }

  &Suffix {
    position: absolute;
    left: 12px;
    z-index: 2;
  }

  &Location {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &Title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--dark-06);
    margin: 0;
    padding: 0 0 4px 0;

    &Secondary {
      font-size: 14px;
    }
  }
}
