@import 'src/styles/responsive';

.parent {
  &Title {
    margin: 0;
  }

  &StaffName {
    font-weight: 700;
  }

  &StaffAddName {
    color: var(--primary-100);
    margin: 0;
  }

  &AddModalContainer {
    width: 100%;

    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding-top: 16px;

    @include adaptive-value(width, 475, 400, 3);
    @include adaptive-value(max-width, $maxWidth, $maxWidthContainer, 3);

    @include device(smallOnly) {
      width: 480px;
    }

    @include device(extraMediumSmall) {
      width: 280px;
    }
  }
}
