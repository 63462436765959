.parent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  background: white;
  opacity: 0.5;
  align-items: center;

  &SpinnerContainer {
    position: fixed;
    top: 60%;
  }
}
