@import '../../../../styles/responsive';

.parent {
  width: 770px;

  @include device(onlyExtraLarge) {
    width: 500px;
  }

  @include device(extraMediumSmall) {
    width: 400px;
  }

  @include device(extraSmall) {
    width: 350px;
  }
}
