.parent {
  width: 100%;
  display: flex;
  row-gap: 8px;
  flex-direction: column;

  &Content {
    width: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
  }

  &Details[open] &Summary {
    background-color: var(--primary-100);
  }

  &Details {
    width: inherit;
  }

  &Details &Summary::before {
    width: 16px;
    height: 16px;
    content: url('../../../../assets/icons/details-status.svg');
    transform: rotate(180deg);
  }

  &Details[open] &Summary::before {
    transform: rotate(0deg);
  }

  &Summary {
    user-select: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
    margin-bottom: 0;
    background-color: var(--light-03);
    padding: 3px 8px 3px 8px;
    border-radius: 2px;
    cursor: pointer;

    display: flex;
    list-style-type: none;
    flex-direction: row-reverse;
    justify-content: space-between;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &Title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--dark-04);
    font-family: 'Inter';
  }

  &RemoveButton {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8px 0 0 0;
  }

  &RoomRemoveIcon {
    width: 16px;
    height: 16px;
  }
}
