@import '../../../../styles/responsive';

.parent {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 0 0 0;

  p {
    @include device(extraMediumSmall) {
      white-space: break-spaces;
    }
  }
}
