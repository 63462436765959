@import '../../styles/responsive';

.parent {
  width: 226px;
  position: relative;

  &Small {
    width: 145px;
  }

  &Details {
    position: relative;
    height: 42px;
    border-radius: 26px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(2, 1fr);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    z-index: 20;
  }

  &Status {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 0 0 0 9px;
    border-radius: 26px 0 0 26px;
    background-color: var(--tertiary-40);
    color: var(--light-01);
  }

  &Star {
    width: 13px;
  }

  &Name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    padding: 0 9px 0 0;
    border-radius: 0 26px 26px 0;
    background-color: var(--light-01);
    color: var(--dark-05);
    cursor: pointer;
    position: relative;

    &[data-validate-identity='true'] {
      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        top: 12px;
        right: 35px;
        border-radius: 50%;
        background-color: var(--danger);
      }
    }
  }

  &DropdownStatus {
    width: 24px;
  }

  &SingleName {
    width: 145px;
    border-radius: 26px;
  }

  &Wrapper {
    position: absolute;
    left: -25px;

    @include device(smallOnly) {
      left: -15px;
    }
  }

  &SingleName &Wrapper {
    left: 0;
  }

  &DropdownActive {
    transform: rotate(180deg);
  }
}
