@import 'src/styles/responsive';

.parent {
  width: 100%;
  display: flex;
  column-gap: 32px;
  row-gap: 24px;
  flex-wrap: wrap;

  @include device(extraMediumSmall) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
