.phoneInputWrapper {
  width: 100%;
}

.phoneInputContainer {
  border: 1px solid var(--light-04);
  border-radius: 6px;
  overflow: hidden;
  height: 40px;

  &.borderOff {
    border: none;
  }
}

.phoneInputSkeleton {
  width: 100% !important;
  height: 40px !important;
}
