.newPatient {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f7;
  border-radius: 12px;
  border: 1px solid #e5e5ea;
  cursor: pointer;
  width: fit-content;
  padding: 0 20px;

  &Label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 10px;
    color: #272322;
  }
}
