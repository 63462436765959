@import '../../../../styles/responsive';

.promotion-checkout-container {
  height: 100vh;

  .billing-details-wrapper {
    padding: 48px 27px;
    background-color: white;
    height: 100%;
    @include device(extraMediumSmall) {
      padding: 24px 30px;
    }

    .section-title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: var(--tertiary-100);
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .items-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 34px;
    }

    .item {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .item-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark-04);
        margin: 0 !important;
      }

      .item-description {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--dark-04);
        margin: 0 !important;
      }
    }
  }

  .payment-wrapper {
    flex: 5;
    box-sizing: border-box;
    padding: 48px 82px;

    @include device(mediumOnly) {
      padding: 48px 27px;
    }

    @include device(smallOnly) {
      padding: 48px 27px;
    }
    @include device(extraMediumSmall) {
      padding: 24px 30px;
    }

    .payment-sticky {
      top: 48px;
      position: sticky;
    }

    .payment-content {
      max-width: 564px;
      @include device(extraLargeOnly) {
        max-width: 100%;
      }
    }

    .section-title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: var(--dark-05);
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .incomplete-details {
      display: flex;
      flex-direction: column;
      box-shadow: 0px 4px 4px 0px #4e4e5a29;
      width: 100%;
      max-width: 564px;
      margin-top: 32px;
      margin-bottom: 24px;
      @include device(extraLargeOnly) {
        max-width: 100%;
      }
      gap: 16px;
      padding: 32px;
      background-color: white;
      border-radius: 14px;
      border: 3px solid var(--primary-100);

      .title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: var(--dark-06);
        margin: 0 !important;
        @include device(smallOnly) {
          text-align: center;
        }
      }

      .description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--dark-04);
        margin: 0 !important;
        @include device(smallOnly) {
          text-align: justify;
        }

        a {
          color: var(--tertiary-100);
        }
      }
    }
  }
}
